import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
import { getactiveidproofs } from "../../../Services/BaseApiService";
import { createbookingSerivce } from "../../../Services/BaseApiPostService";
import { userLogout } from "../../Layouts/UserService";

function Bookignform({
  formPopup,
  setformPopup,
  id,
  getactivesubpackagesdata,
  getAvailableSlotdata,
  dateDeatils,
  per_person_flag,
  MaxPersonAllowed,
  MinPersonAllowed,
  ForeignMaxPersonAllowed,
  ForeignMinPersonAllowed,
  setbookingSummaryId,
}) {
  const [BookingData, SetBookingData] = useState({
    name: "",
    mobile: "",
    user_category: "Indian Adult",
    id_proof_type: "Adhaar",
    id_proof_number: "",
    is_group_leader: "false",
    amount: "0",
    age: "",
    gender: "male",
  });
  const [selectedGender, setSelectedGender] = useState("");
  const [getactiveidproofsdata, setgetactiveidproofs] = useState([]);
  const [group_leader, setgroup_leader] = useState([]);
  const [BookingLoader, setBookingLoader] = useState(false);
  const [grpadminedit, setGrpadminedit] = useState(false);
  const [tableData, settableData] = useState([]);
  const [editid, setEDITid] = useState(0);
  const [BookingDataStatus, SetBookingDataStatus] = useState(null);
  const [isGenderSelected, setIsGenderSelected] = useState(false);
  const [createBookinginfo, setCreateBookinginfo] = useState([]);
  const idProofSelectRef = useRef(null);

  /*useEffect(() => {
     if (BookingData.name) {
       SetBookingDataStatus(BookingData.user_category);
     } else if (tableData.length === 0) {
       SetBookingDataStatus(null);
     }
  }, [BookingData.name]);}*/

  const handleRadiobutton = () => {
    if (BookingData.name) {
      SetBookingDataStatus(BookingData.user_category);
    } else if (tableData.length === 0) {
      SetBookingDataStatus(null);
    }
  };

  const navigate = useNavigate();
  const namevalidation = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g;
  const PhoneNumber =
    /^(?!.*(\d)(?:\D*\1){9})[0-9]{3}[-\s]?[0-9]{3}[-\s]?[0-9]{4}$/g;

  const handleChange = (prop) => (event) => {
    SetBookingData({
      ...BookingData,
      [prop]: event.target.value,
      // gender: event.target.value,
    });
    if (prop === "gender") {
      SetBookingData({
        ...BookingData,
        gender: event.target.value,
      });
      // setIsGenderSelected(true)
    }
  };
  const closeModel = () => {
    setformPopup(false);
    settableData([]);
  };

  const ForeignAdultCount = tableData?.filter(
    (data) => data.user_category === "Foreign Adult"
  );
  const IndianAdultCount = tableData?.filter(
    (data) => data.user_category === "Indian Adult"
  );
  const IndianChildCount = tableData?.filter(
    (data) => data.user_category === "Indian Child"
  );
  const ForeignChildCount = tableData?.filter(
    (data) => data.user_category === "Foreign Child"
  );

  let totalAmountForeign = 0;
  let totalAmountForeignchild = 0;
  let totalAmountIndian = 0;
  let totalAmountIndianchild = 0;

  if (per_person_flag) {
    if (ForeignAdultCount.length > 0) {
      totalAmountForeign = getactivesubpackagesdata
        ?.filter((sbpck) => sbpck._id === id)
        .map(
          (subpackages) =>
            parseFloat(subpackages.foreign_adult) *
            parseFloat(ForeignAdultCount.length)
        );
    } else if (IndianAdultCount.length > 0) {
      totalAmountIndian = getactivesubpackagesdata
        ?.filter((sbpck) => sbpck._id === id)
        .map(
          (subpackages) =>
            parseFloat(subpackages.indian_adult) *
            parseFloat(IndianAdultCount.length)
        );
    } else if (IndianChildCount.length > 0) {
      totalAmountIndian = getactivesubpackagesdata
        ?.filter((sbpck) => sbpck._id === id)
        .map(
          (subpackages) =>
            parseFloat(subpackages.indian_child) *
            parseFloat(IndianChildCount.length)
        );
    } else if (ForeignChildCount.length > 0) {
      totalAmountIndian = getactivesubpackagesdata
        ?.filter((sbpck) => sbpck._id === id)
        .map(
          (subpackages) =>
            parseFloat(subpackages.foreign_child) *
            parseFloat(ForeignChildCount.length)
        );
    }
  } else {
    if (ForeignAdultCount.length > 0) {
      totalAmountForeign = getactivesubpackagesdata
        ?.filter((sbpck) => sbpck._id === id)
        .map((subpackages) => parseFloat(subpackages.foreign_adult));
    } else if (IndianAdultCount.length > 0) {
      totalAmountIndian = getactivesubpackagesdata
        ?.filter((sbpck) => sbpck._id === id)
        .map((subpackages) => parseFloat(subpackages.indian_adult));
    }
  }
  useEffect(() => {
    setgroup_leader(
      tableData?.filter((data) => data?.is_group_leader === "true")
    );
  }, [tableData]);

  const editTableuser = (id) => {
    handleServiceEdit(id);
    setgroup_leader([]);
  };
  const editgroupLeader = () => {
    const group_leaderData = tableData?.filter(
      (data) => data?.is_group_leader === "true"
    );

    if (group_leaderData.length > 0) {
      const findindex = tableData.findIndex((object) => {
        return object.id === group_leaderData[0].id;
      });

      if (group_leaderData.length > 0) {
        tableData[findindex].is_group_leader = "false";
      }
    }
  };

  const dataForm = {
    sub_pkg_id: id,
    date: dateDeatils,
    total_amount:
      parseFloat(totalAmountForeign.toString()) +
      parseFloat(totalAmountIndian.toString()) +
      parseFloat(totalAmountForeignchild.toString()) +
      parseFloat(totalAmountIndianchild.toString()),
    people: tableData,
  };

  const handleServiceDelete = (id) => {
    settableData(tableData.filter((data) => data.id !== id));
  };

  const handleServiceEdit = (id) => {
    const findData = tableData.find((data) => data.id === id);
    SetBookingData(findData);
    setEDITid(id);
  };

  const handleAddandUpdate = (e) => {
    e.preventDefault();
    if (
      subpackage[0].indian_adult === "0" &&
      BookingData.user_category === "Indian Adult"
    ) {
      SetBookingData({ ...BookingData, user_category: "Foreign Adult" });
    } else if (subpackage[0].foreign_adult === "0") {
      SetBookingData({ ...BookingData, user_category: "Indian Adult" });
    }
    if (editid) {
      const editdata = tableData.find((data) => data.id === editid);
      const updateData = tableData.map((value) =>
        value.id === editdata.id
          ? (value = {
              id: value.id,
              name: BookingData.name,
              mobile: BookingData.mobile,
              user_category: BookingData.user_category,
              id_proof_type: BookingData.id_proof_type,
              id_proof_number: BookingData.id_proof_number,
              insurance_number: BookingData.insurance_number,
              is_group_leader: BookingData.is_group_leader,
              amount: BookingData.amount,
              age: BookingData.age,
              gender: BookingData.gender,
            })
          : {
              id: value.id,
              name: value.name,
              mobile: value.mobile,
              user_category: value.user_category,
              id_proof_type: value.id_proof_type,
              id_proof_number: value.id_proof_number,
              insurance_number: value.insurance_number,
              is_group_leader: value.is_group_leader,
              amount: value.amount,
              age: value.age,
              gender: BookingData.gender,
            }
      );

      settableData(updateData);
      //sessionStorage.setItem("tableData", JSON.stringify(tableData));
      setEDITid(0);
      SetBookingData({
        name: "",
        mobile: "",
        user_category: BookingData.user_category,
        id_proof_type: "",
        id_proof_number: "",
        insurance_number: "",
        is_group_leader: "false",
        amount: "0",
        age: "",
        gender: "",
      });
      setgetactiveidproofs([]);
      getactiveidproofs(setgetactiveidproofs);
    } else {
      settableData([
        ...tableData,
        {
          id: uuidv4(),
          name: BookingData.name,
          mobile: BookingData.mobile,
          user_category: BookingData.user_category,
          id_proof_type: BookingData.id_proof_type,
          id_proof_number: BookingData.id_proof_number,
          insurance_number: BookingData.insurance_number,
          is_group_leader: BookingData.is_group_leader,
          amount: BookingData.amount,
          age: BookingData.age,
          gender: BookingData.gender,
        },
      ]);
      SetBookingData({
        name: "",
        mobile: "",
        user_category: BookingData.user_category,
        id_proof_type: "",
        id_proof_number: "",
        insurance_number: "",
        is_group_leader: "false",
        amount: "0",
        age: "",
        gender: "",
      });
      setgetactiveidproofs([]);
      getactiveidproofs(setgetactiveidproofs);
    }
  };

  const handleServiceAdd = (e) => {
    e.preventDefault();
    handleRadiobutton();

    if (BookingData.name.trim() === "") {
      toast.error("Please fill the name");
    } else if (BookingData.mobile.trim() === "") {
      toast.error("Please enter mobile number");
    } else if (BookingData.id_proof_type.trim() == "") {
      toast.error("Please select  ID proof type");
    } else if (BookingData.id_proof_number.trim() === "") {
      toast.error("Please enter id proof number");
    }
    //else if (BookingData.insurance_number.trim() === '') {
    // toast.error('Please enter insurance number')
    //}
    else if (BookingData.age.trim() === "") {
      toast.error("Please enter age");
    } else if (BookingData.age < 15) {
      toast.error("Age should be greater than 14");
      SetBookingData((prv) => {
        return {
          ...prv,
          age: "",
        };
      });
    } else if (!BookingData.id_proof_type) {
      toast.error("Select ID Proof Type");
    } else if (
      BookingData.gender !== "male" &&
      BookingData.gender !== "female" &&
      BookingData.gender !== "transgender"
    ) {
      toast.error("please select a valid gender");
    } else if (!namevalidation.test(BookingData.name)) {
      toast.error("Please enter valid name");
    } else if (!PhoneNumber.test(BookingData.mobile)) {
      toast.error("Please enter valid mobile number");
    } else if (BookingData.id_proof_number.length >= 20) {
      toast.error("Id proof number is less than 20");
    } else if (getAvailableSlotdata?.slots <= tableData.length) {
      toast.error("Slot is full");

      if (editid) {
        handleAddandUpdate(e);
      } else {
      }
    } else if (!editid) {
      if (MaxPersonAllowed <= tableData.length) {
        toast.error("Max person limit is reached ");
        SetBookingData({
          name: "",
          mobile: "",
          user_category: "Indian Adult",
          id_proof_type: "Adhaar",
          id_proof_number: "",
          insurance_number: "",
          is_group_leader: "false",
          amount: "0",
          age: "",
          gender: "",
        });
      } else if (BookingData.user_category === "Foreign Adult") {
        if (ForeignMaxPersonAllowed <= tableData.length) {
          toast.error("Foreign Max person limit is reached ");
          SetBookingData({
            name: "",
            mobile: "",
            user_category: "Foreign Adult",
            id_proof_type: "Adhaar",
            id_proof_number: "",
            insurance_number: "",
            is_group_leader: "false",
            amount: "0",
            age: "",
            gender: "",
          });
        }

        handleAddandUpdate(e);
      } else {
        handleAddandUpdate(e);
      }
    }

    // else if (BookingData.id_proof_type === "Adhaar") {
    //   if (!adhr.test(BookingData.id_proof_number)) {
    //     toast.error("Please enter a valid aadhaar number");
    //   } else {
    //     handleAddandUpdate(e);
    //   }
    // }
    else {
      handleAddandUpdate(e);
    }
  };

  const createbooking = async (e) => {
    // e.preventDefault();
    setBookingLoader(true);
    const res = await createbookingSerivce(dataForm);
    if (res?.data?.status) {
      // toast.success(res?.data?.message);
      setBookingLoader(false);
      setformPopup(false);

      setbookingSummaryId(res.data.data.booking._id);
      sessionStorage.setItem(
        "bookingSummaryId",
        JSON.stringify(res.data.data.booking._id)
      );
      navigate(`/bookingSummary`);
      setCreateBookinginfo(
        res.data.data.booking.createBookinginfo.is_approval_required
      );
    } else {
      if (res?.data.message === "Invalid token") {
        userLogout();
      }
      toast.error(res?.data?.message);
      setBookingLoader(false);
    }
  };

  const handleSubmit = (e) => {
    // if (!isGenderSelected) {
    //   toast.error('Please select a gender')
    //   return
    // }
    if (
      BookingData.name.trim() ||
      BookingData.mobile.trim() ||
      BookingData.id_proof_number.trim() ||
      BookingData.insurance_number.trim()
    ) {
      toast.error(
        "Some data found in fields. Please click 'Add/Update' before submit."
      );
    } else if (group_leader?.length === 0) {
      toast.error("Update a user as group leader to proceed");
    } else {
      if (totalAmountIndian) {
        if (MinPersonAllowed <= IndianAdultCount.length) {
          if (MaxPersonAllowed >= IndianAdultCount.length) {
            createbooking(e);
          } else {
            toast.error("Please Fill Max Person Allowed");
          }
        } else {
          toast.error("Please Fill Min Person Allowed");
        }
      }
      if (totalAmountForeign) {
        if (MinPersonAllowed <= ForeignAdultCount.length) {
          if (MaxPersonAllowed >= ForeignAdultCount.length) {
            createbooking(e);
          } else {
            toast.error("Please Fill Max Person Allowed");
          }
        } else {
          toast.error("Please Fill Min Person Allowed");
        }
      }
      if (totalAmountForeignchild) {
        if (MinPersonAllowed <= IndianChildCount.length) {
          if (MaxPersonAllowed >= IndianChildCount.length) {
            createbooking(e);
          } else {
            toast.error("Please Fill Max Person Allowed");
          }
        } else {
          toast.error("Please Fill Min Person Allowed");
        }
      }
      if (totalAmountIndianchild) {
        if (MinPersonAllowed <= IndianChildCount.length) {
          if (MaxPersonAllowed >= IndianChildCount.length) {
            createbooking(e);
          } else {
            toast.error("Please Fill Max Person Allowed");
          }
        } else {
          toast.error("Please Fill Min Person Allowed");
        }
      }
    }
  };
  useEffect(() => {
    if (editid) {
      editgroupLeader();
    }
  }, [grpadminedit]);

  useEffect(() => {
    if (tableData.length > 0) {
      sessionStorage.removeItem("tableData");
      sessionStorage.setItem("tableData", JSON.stringify(tableData));
    }
  }, [tableData]);

  useEffect(() => {
    getactiveidproofs(setgetactiveidproofs);
    const storedTableData = sessionStorage.getItem("tableData");
    if (storedTableData) {
      settableData(JSON.parse(sessionStorage.getItem("tableData")));
      SetBookingData({
        name: "",
        mobile: "",
        user_category: "Indian Adult",
        id_proof_type: "Adhaar",
        id_proof_number: "",
        insurance_number: "",
        is_group_leader: "false",
        amount: "0",
        age: "",
        gender: "",
      });
    }

    // Attach event listener to handle page leave
    window.addEventListener("beforeunload", handlePageLeave);

    return () => {
      // Remove event listener on component unmount
      window.removeEventListener("beforeunload", handlePageLeave);
    };
  }, []);

  const handlePageLeave = () => {
    // Clear session storage when leaving the page (e.g., refreshing)
    sessionStorage.removeItem("tableData");
  };
  const subpackage = getactivesubpackagesdata?.filter(
    (sbpck) => sbpck._id === id
  );
  useEffect(() => {
    if (subpackage[0].indian_adult === "0") {
      toast.error("Attention:This Package is not available  for Indians!");
      SetBookingData({ ...BookingData, user_category: "Foreign Adult" });
    } else if (subpackage[0].foreign_adult === "0") {
      toast.error("Attention:This package is not available for Foreigners!");
    }
  }, []);

  return (
    <>
      <div className="modelbooking">
        <div
          className="modal loginModal"
          id="myModal"
          style={{ display: `${formPopup ? `block` : `none`}` }}
        >
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title">Booking Form</h3>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => closeModel()}
                ></button>
              </div>

              <div className="modal-body">
                {BookingLoader && createBookinginfo ? (
                  <>
                    <div>
                      <h1>
                        You can proceed with the payment once the approval
                        process is completed. After approval "Pay now" button
                        will enable in my booking.
                      </h1>
                    </div>
                    <div
                      className="loadingMainSection"
                      style={{ height: "300px" }}
                    >
                      <div className="loader"></div>
                    </div>
                  </>
                ) : BookingLoader ? (
                  <>
                    <div
                      className="loadingMainSection"
                      style={{ height: "300px" }}
                    >
                      <div className="loader"></div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="Slot">
                      <div className="warningmsg">
                        <p
                          style={{
                            color: "red",
                            fontSize: "13px",
                            marginLeft: "3px",
                          }}
                        >
                          <span>Important</span>: The details of all group
                          members must be entered in the form below. These
                          details will be verified at the reporting place.
                          Visitors whose information is not on the booking
                          schedule will not be permitted entry.
                        </p>
                        <div className="d-flex">
                          <p>Available Slots: {getAvailableSlotdata?.slots}</p>
                          {getactivesubpackagesdata
                            ?.filter((sbpck) => sbpck._id === id)
                            .map((subpackages) => (
                              <div key={subpackages._id} className="maxandmin">
                                <p>
                                  Max Person Allowed:{" "}
                                  {subpackages.max_person_allowed}
                                </p>
                                <p>
                                  Min Person Allowed:{" "}
                                  {subpackages.min_person_allowed}
                                </p>
                                {/*<p>
                              Foreign Max Person Allowed:{" "}
                              {subpackages.foreign_max_person_allowed}
                            </p>
                            <p>
                              Foreign Min Person Allowed:{" "}
                              {subpackages.foreign_min_person_allowed}
                        </p>*/}
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div className="create_bookingformConatiner">
                      <form action="">
                        <section>
                          <div className="user_category mt-0">
                            {getactivesubpackagesdata
                              ?.filter((sbpck) => sbpck._id === id)
                              .map((subpackages) => (
                                <div key={subpackages._id}>
                                  {subpackages.indian_adult !== 0 && (
                                    <div
                                      style={{
                                        display:
                                          BookingDataStatus ===
                                            "Indian Adult" ||
                                          BookingDataStatus === null
                                            ? "felx"
                                            : "none",
                                      }}
                                    >
                                      <input
                                        type="radio"
                                        id="Indian Adult"
                                        name="user_category"
                                        value="Indian Adult"
                                        checked={
                                          BookingData.user_category ===
                                          "Indian Adult"
                                        }
                                        disabled={subpackages.indian_adult == 0}
                                        onChange={handleChange("user_category")}
                                      />
                                      <label for="html">Indian</label>
                                    </div>
                                  )}
                                  {subpackages.foreign_adult !== 0 && (
                                    <div
                                      style={{
                                        display:
                                          BookingDataStatus ===
                                            "Foreign Adult" ||
                                          BookingDataStatus === null
                                            ? "flex"
                                            : "none",
                                      }}
                                    >
                                      <input
                                        type="radio"
                                        className="Foreign_radio"
                                        id="Foreign Adult"
                                        name="user_category"
                                        value="Foreign Adult"
                                        disabled={
                                          subpackages.foreign_adult == 0
                                        }
                                        checked={
                                          BookingData.user_category ===
                                          "Foreign Adult"
                                        }
                                        onChange={handleChange("user_category")}
                                      />
                                      <label for="css">Foreign</label>
                                    </div>
                                  )}
                                  {/* {subpackages.foreign_adult === "0" ? (
                                    ""
                                  ) : (
                                    <>
                                    {totalAmountForeign}
                                      {totalAmountForeign ? (
                                        " "
                                      ) : (
                                        <>
                                          <input
                                            type="radio"
                                            id="Indian Adult"
                                            name="user_category"
                                            value="Indian Adult"
                                            checked={
                                              BookingData.user_category ===
                                              "Indian Adult"
                                            }
                                            // value={singleService.user_category}
                                            // value={productData.product_name}
                                            onChange={handleChange(
                                              "user_category"
                                            )}
                                            // onChange={(e) => handleServiceChange(e, index)}
                                          />
                                          <label for="html">Indian</label>
                                        </>
                                      )}
                                      {!totalAmountIndian ? (
                                        <>
                                          <input
                                            type="radio"
                                            className="Foreign_radio"
                                            id="Foreign Adult"
                                            name="user_category"
                                            value="Foreign Adult"
                                            checked={
                                              BookingData.user_category ===
                                              "Foreign Adult"
                                            }
                                            onChange={handleChange(
                                              "user_category"
                                            )}
                                          />
                                          <label for="css">Foreign</label>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  )} */}
                                </div>
                              ))}
                          </div>
                          <div className="inputFiledsConatiner">
                            <div>
                              <input
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={BookingData.name}
                                onChange={handleChange("name")}
                              />
                            </div>
                            <div>
                              <input
                                type="number"
                                name="mobile"
                                placeholder="Mobile Number"
                                value={BookingData.mobile}
                                onChange={handleChange("mobile")}
                              />
                            </div>

                            {/* <div>
                              
                              <select
                                name='id_proof_type'
                                id='id_proof_type'
                                onChange={handleChange('id_proof_type')}
                              >
                                <option disabled selected>
                                  Select ID Proof
                                </option>
                                {getactiveidproofsdata?.map((ids, index) => (
                                  <option key={index} value={ids.title}>
                                    {ids.title}
                                  </option>
                                ))}
                              </select>
                            </div> */}

                            <div>
                              <select
                                name="id_proof_type"
                                id="id_proof_type"
                                onChange={handleChange("id_proof_type")}
                              >
                                <option value="">Select ID Proof</option>
                                {getactiveidproofsdata?.map((ids, index) => (
                                  <option key={index} value={ids.title}>
                                    {ids.title}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div>
                              {/* <label htmlFor="">Id Proof Number :</label> */}
                              <input
                                type="text"
                                ref={idProofSelectRef}
                                placeholder="Id Proof Number"
                                name="id_proof_number"
                                value={BookingData.id_proof_number}
                                onChange={handleChange("id_proof_number")}
                              />
                            </div>
                            <div>
                              {/* <label htmlFor="">Insurance Number :</label> */}
                              <input
                                type="text"
                                name="insurance_number"
                                placeholder="Insurance Number"
                                value={BookingData.insurance_number}
                                onChange={handleChange("insurance_number")}
                              />
                            </div>
                            <div>
                              {/* <label htmlFor="">Insurance Number :</label> */}
                              <input
                                type="number"
                                name="age"
                                placeholder="Age"
                                value={BookingData.age}
                                onChange={handleChange("age")}
                              />
                            </div>
                            <div className="radioContainer">
                              <label>Gender</label>
                              <input
                                type="radio"
                                id="male"
                                name="gender"
                                value="male"
                                checked={BookingData.gender === "male"}
                                onChange={handleChange("gender")}
                              />
                              <label htmlFor="male">Male</label>
                              <input
                                type="radio"
                                id="female"
                                name="gender"
                                value="female"
                                checked={BookingData.gender === "female"}
                                onChange={handleChange("gender")}
                              />
                              <label htmlFor="female">Female</label>
                              <input
                                type="radio"
                                id="transgender"
                                name="gender"
                                value="transgender"
                                checked={BookingData.gender === "transgender"}
                                onChange={handleChange("gender")}
                              />
                              <label htmlFor="transgender">Other</label>
                            </div>
                          </div>

                          <div className="grpConatiner">
                            <div className="group_leader">
                              {group_leader?.length === 1 ? (
                                " "
                              ) : (
                                <>
                                  <input
                                    type="checkbox"
                                    id=""
                                    name="is_group_leader"
                                    value={
                                      BookingData.is_group_leader === "true"
                                        ? "false"
                                        : "true"
                                    }
                                    onChange={handleChange("is_group_leader")}
                                    onClick={() =>
                                      setGrpadminedit(!grpadminedit)
                                    }
                                    checked={
                                      BookingData.is_group_leader === "true"
                                        ? true
                                        : false
                                    }
                                  />
                                  <label htmlFor="">Group Leader</label>
                                </>
                              )}
                            </div>
                            {tableData.length > 0 ? (
                              <div className="amountConatiner">
                                {getactivesubpackagesdata
                                  ?.filter((sbpck) => sbpck._id === id)
                                  .map((subpackages) => (
                                    <div key={subpackages._id}>
                                      {per_person_flag ? (
                                        <>
                                          {totalAmountForeign ? (
                                            <>
                                              <label htmlFor="">Amount :</label>
                                              <p>
                                                Rs.
                                                {subpackages.foreign_adult *
                                                  tableData.length}
                                                .00
                                              </p>
                                            </>
                                          ) : (
                                            <>
                                              <label htmlFor="">Amount :</label>
                                              <p>
                                                Rs.
                                                {subpackages.indian_adult *
                                                  tableData.length}
                                                .00
                                              </p>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {totalAmountForeign ? (
                                            <>
                                              <label htmlFor="">Amount :</label>
                                              <p>
                                                Rs. {subpackages.foreign_adult}
                                                .00
                                              </p>
                                            </>
                                          ) : (
                                            <>
                                              <label htmlFor="">Amount :</label>
                                              <p>
                                                Rs. {subpackages.indian_adult}
                                                .00
                                              </p>
                                            </>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  ))}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="btnConatiner">
                            {editid ? (
                              <button
                                className="mainButton printBtn"
                                onClick={(e) => handleServiceAdd(e)}
                              >
                                Update
                              </button>
                            ) : (
                              <button
                                className="mainButton"
                                onClick={(e) => handleServiceAdd(e)}
                              >
                                Add
                              </button>
                            )}
                          </div>
                        </section>
                      </form>
                      <div className="tableSection">
                        {tableData.length === 0 ? (
                          ""
                        ) : (
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Sl.No.</th>
                                <th scope="col">Name</th>
                                <th scope="col">Mobile Number</th>
                                <th scope="col">Nationality</th>
                                <th scope="col">Proof Type</th>
                                <th scope="col">Proof Number</th>
                                <th scope="col">Insurance Number</th>
                                <th scope="col">Age</th>
                                <th scope="col">Leader</th>
                                <th scope="col">Gender</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tableData?.map((data, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{data.name}</td>
                                  <td>{data.mobile}</td>
                                  <td>{data.user_category}</td>
                                  <td>{data.id_proof_type}</td>
                                  <td>{data.id_proof_number}</td>
                                  <td>{data.insurance_number}</td>
                                  <td>{data.age}</td>
                                  <td>
                                    {data.is_group_leader === "true"
                                      ? "Yes"
                                      : "-"}
                                  </td>
                                  <td>{data.gender}</td>
                                  <td>
                                    <button
                                      onClick={() => editTableuser(data.id)}
                                    >
                                      <i className="fa-solid fa-pen-to-square"></i>
                                    </button>
                                    <button
                                      onClick={() =>
                                        handleServiceDelete(data.id)
                                      }
                                    >
                                      <i className="fa-solid fa-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                        <div className="submitbtn">
                          {tableData.length > 0 ? (
                            <button
                              className="mainButton"
                              onClick={() => handleSubmit()}
                            >
                              Submit
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Bookignform;
