import axios from "axios";
import { BaseUrl } from "./baseUrl";
const userInfo = JSON.parse(localStorage.getItem("user"));

const axiosPostCall = async (url, dataform, setData) => {
  await axios
    .post(`${BaseUrl}/${url}`, dataform)
    .then((res) => setData(res.data))
    .catch((err) => console.log(err));
};
const axiosPostCallauth = async (url, dataForm) => {
  const res = await axios.post(`${BaseUrl}/${url}`, dataForm, {
    headers: {
      Authorization: "Bearer " + userInfo?.session,
    },
  });

  return res;
};

export const getcommons = async (dataForm, setCommonBased) => {
  await axiosPostCall("web/get_commons", dataForm, setCommonBased);
};
export const createbookingSerivce = async (dataForm) => {
  const res = await axiosPostCallauth("booking/create_booking", dataForm);

  return res;
};
export const checkPackageAvailabilitySerivce = async (dataForm) => {
  const res = await axiosPostCallauth("web/getPackageAvailability", dataForm);

  return res;
};
export const getAvailableSlotSerivce = async (dataForm) => {
  const res = await axiosPostCallauth("booking/get_available_slots", dataForm);

  return res;
};
export const getBookingSummarySerivce = async (dataForm) => {
  const res = await axiosPostCallauth(
    "admin/booking/get_booking_details",
    dataForm
  );

  return res;
};
export const getTicketBookingSummarySerivce = async (dataForm) => {
  const res = await axiosPostCallauth(
    "admin/booking/get_booking_details",
    dataForm
  );

  return res;
};
export const getTicketBookingSerivce = async (dataForm) => {
  const res = await axiosPostCallauth("booking/ticket_booking", dataForm);

  return res;
};
export const stayavailableslotsSerivce = async (dataForm) => {
  const res = await axiosPostCallauth("booking/stay_available_slots", dataForm);

  return res;
};
export const trekkingavailableslotsSerivce = async (dataForm) => {
  const res = await axiosPostCallauth("booking/get_available_slots", dataForm);

  return res;
};
export const staybookingSerivce = async (dataForm) => {
  const res = await axiosPostCallauth("booking/stay_booking", dataForm);

  return res;
};

export const updateProfileSerivce = async (dataForm) => {
  const res = await axiosPostCallauth("admin/users/updateprofile", dataForm);

  return res;
};


export const postIdSection = async (dataForm) => {
  const res = await axiosPostCallauth("admin/booking/get_booking_details", dataForm);

  return res;
};
export const getPaymentStatus = async (dataForm) => {
  const res = await axiosPostCallauth("admin/booking/get_payment_status", dataForm);

  return res;
};