import React, { useEffect, useState } from 'react'
import './stay.scss'
import '../placeDetails/placeDetails.css'
import { useParams } from 'react-router-dom'
import innerBanner from '../../../Assets/Images/inner-banner/inner-banner.jpg'
import { BaseUrl } from '../../../Services/baseUrl'
import createDOMPurify from 'dompurify'
import ImageGallery from 'react-image-gallery'
import { toast } from 'react-toastify'
import { Calendar } from 'primereact/calendar'
import { useNavigate } from 'react-router-dom'
import { getactiveidproofs } from '../../../Services/BaseApiService'
import {
  stayavailableslotsSerivce,
  staybookingSerivce,
} from '../../../Services/BaseApiPostService'
import { userLogout } from '../../Layouts/UserService'
import Loading from '../../Layouts/Loading'
import { convert, getCurrentDate } from '../../../Services/CommonService'
import { formatDate } from '@fullcalendar/react'
import moment from 'moment'
const warningStyle = {
  padding: '10px',
  color: '#ff0000',
  // fontWeight: 'bold',
}
function Stay({
  getactivesubpackagesdata,
  getactivePackagesdata,
  getactivestaydata,
  loginPopup,
  setloginPopup,
  setbookingSummaryId,
}) {
  const params = useParams()
  const { id } = params
  const [startDate, setstartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [noofroom, setNoOfroom] = useState('1')
  const [noofadult_count, setNoOfadult_count] = useState('1')
  const [noofchild_count, setNoOfchild_count] = useState('0')
  const [user_category, setUser_category] = useState('Indian')
  const [name, setName] = useState('')
  const [id_proof_type, setId_proof_type] = useState('Adhaar')
  const [id_proof_number, setId_proof_number] = useState('')
  const [insurance_number, setInsurance_number] = useState('')
  const [phone_number, setPhone_number] = useState('')
  const [getactiveidproofsdata, setgetactiveidproofs] = useState([])
  const [stayavailableslotsdata, setstayavailableslots] = useState([])
  const [is_slots, setis_Slots] = useState(false)
  const [loader, setLoader] = useState(false)
  const [dates, setDates] = useState(null)

  const userInfo = JSON.parse(localStorage.getItem('user'))
  const navigate = useNavigate()

  const formatDate = (date) => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  const adhr = /^\d{4}\s\d{4}\s\d{4}$/g
  const namevalidation = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g
  const PhoneNumber = /^[0-9]{10}$/
  const DOMPurify = createDOMPurify(window)

  const activeStayId = getactivestaydata?.filter((Stay) => Stay._id === id)

  const subPackage = getactivesubpackagesdata?.filter(
    (sub) => sub.pkg_id === id
  )

  var facilities = activeStayId[0]?.facilities?.split(',')
  var activities = activeStayId[0]?.activities?.split(',')

  const images = [
    {
      original: `${BaseUrl}/${activeStayId[0]?.image1}`,
      thumbnail: `${BaseUrl}/${activeStayId[0]?.image1}`,
    },
    {
      original: `${BaseUrl}/${activeStayId[0]?.image2}`,
      thumbnail: `${BaseUrl}/${activeStayId[0]?.image2}`,
    },
    {
      original: `${BaseUrl}/${activeStayId[0]?.image3}`,
      thumbnail: `${BaseUrl}/${activeStayId[0]?.image3}`,
    },
    {
      original: `${BaseUrl}/${activeStayId[0]?.image4}`,
      thumbnail: `${BaseUrl}/${activeStayId[0]?.image4}`,
    },
    {
      original: `${BaseUrl}/${activeStayId[0]?.image5}`,
      thumbnail: `${BaseUrl}/${activeStayId[0]?.image5}`,
    },
  ]

  const listCount = (count) => {
    const options = []
    for (let i = 1; i <= count; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      )
    }
    return options
  }

  const stayavailableslots = async (start_date, end_date) => {
    if (start_date < end_date) {
      const slotsData = {
        sub_pkg_id: subPackage[0]?._id,
        start_date: convertDateFormat(start_date),
        end_date: convertDateFormat(end_date),
      }
      const res = await stayavailableslotsSerivce(slotsData)

      if (res?.data.status) {
        setstayavailableslots(res.data.data)
        setis_Slots(true)
      } else {
        toast.error(res.data.message)
        if (res.data.message === 'Invalid token') {
          userLogout()
        }
      }
    } else if (startDate === endDate) {
      // toast.error("Start date and End date can't be the same.")
    }
  }
  const convertDateFormat = (date) => {
    const [month, day, year] = date.split('/')

    // Format the date in the desired output format
    const formattedDate = `${month}-${day}-${year}`

    return formattedDate
  }
  const formatStartDate = (dates) => {
    if (Array.isArray(dates) && dates.length === 2) {
      const today = new Date()
      const startDate = new Date(dates[0])
      const endDate = new Date(dates[1])
      setstartDate(startDate)
      setEndDate(endDate)
      stayavailableslots(
        moment(startDate).format('DD/MM/YYYY'),
        moment(endDate).format('DD/MM/YYYY')
      )
    }

    return null // Return null for invalid input
  }

  useEffect(() => {
    formatStartDate(dates)
  }, [dates])

  useEffect(() => {
    getactiveidproofs(setgetactiveidproofs)
    const storedData = sessionStorage.getItem('tableData')
    if (storedData) {
      const fData = JSON.parse(sessionStorage.getItem('tableData'))
      let from_date = fData.start_date
      const parts = from_date.split('-')
      from_date = parts[1] + '/' + parts[0] + '/' + parts[2]

      let to_date = fData.end_date
      const parts2 = to_date.split('-')
      to_date = parts2[1] + '/' + parts2[0] + '/' + parts2[2]
      const stDate = new Date(from_date)
      const edDate = new Date(to_date)
      setDates([stDate, edDate])
      setNoOfroom(fData.no_of_rooms)
      //setDates(ddate);
      setNoOfadult_count(fData.adult_count)
      setNoOfchild_count(fData.child_count)
      setUser_category(fData.user_category)
      setName(fData.people.name)
      setId_proof_type(fData.people.id_proof_type)
      setId_proof_number(fData.people.id_proof_number)
      setInsurance_number(fData.people.insurance_number)
      setPhone_number(fData.people.mobile)
    }
    // Attach event listener to handle page leave
    window.addEventListener('beforeunload', handlePageLeave)

    return () => {
      // Remove event listener on component unmount
      window.removeEventListener('beforeunload', handlePageLeave)
    }
  }, [])

  const handlePageLeave = () => {
    // Clear session storage when leaving the page (e.g., refreshing)
    sessionStorage.removeItem('tableData')
  }

  // useEffect(() => {
  //   setis_Slots(false)
  //   if (startDate && endDate) {
  //     stayavailableslots()
  //   }
  // }, [startDate, endDate])
  const staybooking = async (dataForm) => {
    const res = await staybookingSerivce(dataForm)
    setLoader(true)
    if (res.data.status) {
      setbookingSummaryId(res.data.data.booking._id)
      sessionStorage.setItem(
        'bookingSummaryId',
        JSON.stringify(res.data.data.booking._id)
      )
      navigate(`/bookingSummary`)
      setLoader(false)
    } else {
      toast.error(res.data.message)
      setLoader(false)
    }
  }
  const submitHandlers = (e) => {
    e.preventDefault()
    setLoader(true)
    sessionStorage.removeItem('tableData')
    if (
      //startDate.trim() === '' ||
      // endDate.trim() === '' ||
      name.trim() === '' ||
      id_proof_number.trim() === '' ||
      insurance_number.trim() === '' ||
      phone_number.trim() === ''
    ) {
      toast.error('Please fill the fields')
      setLoader(false)
    } else {
      const difference = Math.abs(new Date(endDate) - new Date(startDate))
      var days = Math.ceil(difference / (1000 * 3600 * 24))
      let totalAmount = '0'
      setLoader(false)

      if (stayavailableslotsdata?.slots > 0) {
        if (user_category === 'Indian') {
          // if (subPackage[0]?.per_person_flag === false) {
          //   totalAmount =
          //     parseFloat(noofroom) *
          //     parseFloat(subPackage[0].indian_adult) *
          //     parseFloat(days)

          // } else {
          if (
            parseFloat(noofadult_count) <
            parseFloat(noofroom) * parseFloat(subPackage[0].min_person_allowed)
          ) {
            totalAmount =
              parseFloat(noofroom) *
              // parseFloat(subPackage[0].min_person_allowed) *
              parseFloat(subPackage[0].indian_adult) *
              parseFloat(days)
          } else {
            const grandAmount =
              parseFloat(noofroom) *
              // subPackage[0].min_person_allowed *
              parseFloat(subPackage[0].indian_adult) *
              days
            const additionalPerson =
              noofadult_count - parseFloat(subPackage[0].min_person_allowed)
            const additionalAmount =
              additionalPerson * subPackage[0].additional_person_amount * days
            totalAmount = grandAmount + additionalAmount
            // totalAmount =
            // parseFloat(noofroom) *
            // parseFloat(subPackage[0].indian_adult) *
            // days;
          }
        }
        //}
        else {
          // if (subPackage[0]?.per_person_flag === false) {
          //   totalAmount =
          //     parseFloat(noofroom) *
          //     parseFloat(subPackage[0].foreign_adult) *
          //     parseFloat(days)
          // } else {
          if (
            parseFloat(noofadult_count) <
            parseFloat(noofroom) * parseFloat(subPackage[0].min_person_allowed)
          ) {
            totalAmount =
              parseFloat(noofroom) *
              // parseFloat(subPackage[0].min_person_allowed) *
              parseFloat(subPackage[0].foreign_adult) *
              parseFloat(days)
          } else {
            const grandAmount =
              parseFloat(noofroom) *
              // subPackage[0].min_person_allowed *
              parseFloat(subPackage[0].foreign_adult) *
              days
            const additionalPerson =
              noofadult_count - parseFloat(subPackage[0].min_person_allowed)
            const additionalAmount =
              additionalPerson *
              subPackage[0].extra_person_foreign_amount *
              days

            totalAmount = grandAmount + additionalAmount

            // totalAmount =
            //   parseFloat(noofroom) *
            //   parseFloat(subPackage[0].foreign_adult) *
            //   parseFloat(days);
            //}
          }
        }
        const dataForm = {
          sub_pkg_id: subPackage[0]._id,
          start_date: convert(startDate),
          end_date: convert(endDate),
          no_of_rooms: noofroom,
          user_category: user_category,
          adult_count: noofadult_count,
          child_count: noofchild_count,
          total_amount: totalAmount,
          people: {
            name: name,
            user_category: user_category,
            id_proof_type: id_proof_type,
            id_proof_number: id_proof_number,
            insurance_number: insurance_number,
            mobile: phone_number,
            amount: '0',
          },
        }
        if (name.trim() === '') {
          toast.error('Please fill Name')
        } else if (id_proof_number.trim() === '') {
          toast.error('Please fill Id Proof Number')
        } else if (insurance_number.trim() === '') {
          toast.error('Please fill Insurance Number')
        } else if (!namevalidation.test(name)) {
          toast.error('Please enter a valid name')
        } else if (!PhoneNumber.test(phone_number)) {
          toast.error('Please enter valid phone number')
        } else if (startDate === endDate) {
          toast.error("Start date and End date can't be the same")
        } else {
          if (dataForm) {
            sessionStorage.setItem('tableData', JSON.stringify(dataForm))
            staybooking(dataForm)
          }
        }
      } else {
        toast.error('Rooms are not available')
        setLoader(false)
      }
    }
  }

  if (!userInfo) {
    setloginPopup(true)
  }
  if (loader) {
    ;<Loading />
  }
  return (
    <div className='placeDetails'>
      <div className='innerpage-banners'>
        <img src={innerBanner} alt='inner banner' />
      </div>
      <div className='container'>
        <div className='placeDetailsinner'>
          <div className='placeDetailsinnerTxt'>
            <h1>{activeStayId[0]?.name}</h1>
            {/* <h1>Stay Heading</h1> */}
          </div>
          <div className='row '>
            <div className='col-md-6'>
              <div className='placeDetailsinnerTxtbox'>
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(activeStayId[0]?.des),
                  }}
                  className='mb-5'
                />
                <div className='row listdata'>
                  <div className='col-md-6'>
                    <h4>
                      Check In: <span>{activeStayId[0]?.check_in}</span>{' '}
                    </h4>
                  </div>
                  <div className='col-md-6'>
                    <h4>
                      Check Out:
                      <span>{activeStayId[0]?.check_out}</span>
                    </h4>
                  </div>
                  {/* <div className="col-md-6">
                    <h4>
                      Reporting Time:
                      <span>{subPackage[0]?.reporting_time}</span>
                    </h4>
                  </div>
                  <div className="col-md-6">
                    <h4>
                      Reporting Place:
                      <span>{subPackage[0]?.reporting_place}</span>
                    </h4>
                  </div> */}
                  {/* <div className='col-md-6'>
                    <h4>
                      Additional Person Amount:
                      <span>{subPackage[0]?.additional_person_amount}</span>
                    </h4>
                  </div> */}
                  <div className='col-md-6'>
                    <h4>
                      Max Person Allowed:
                      <span>{subPackage[0]?.max_person_allowed}</span>
                    </h4>
                  </div>
                  <div className='col-md-6'>
                    <h4>
                      Min person allowed:
                      <span>{subPackage[0]?.min_person_allowed}</span>
                    </h4>
                  </div>
                </div>
                <table class='table'>
                  <thead>
                    <tr>
                      <th scope='col'>Nationality</th>
                      <th scope='col'>Adult</th>
                      <th scope='col'>Child</th>
                      <th scope='col'>Extra Person Amount</th>
                      {/* <th scope="col">Child (Age 5 - 13)</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope='row'>Indian</th>
                      <td>{subPackage[0]?.indian_adult} INR</td>
                      <td>Nil</td>
                      {/* <td> {subPackage[0]?.indian_child_agerange} INR</td> */}
                      <td>{subPackage[0]?.extra_person_indian_amount} INR</td>
                    </tr>
                    <tr>
                      <th scope='row'>Foreign</th>
                      <td>{subPackage[0]?.foreign_adult} INR</td>
                      <td>Nil</td>
                      <td>{subPackage[0]?.extra_person_foreign_amount} INR</td>
                      {/* <td> {subPackage[0]?.foreign_child_agerange} INR</td> */}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className='col-md-6'>
              <ImageGallery items={images} lazyLoad={true} />
            </div>
            {subPackage[0]?.is_online === true ? (
              <div className='col-md-12'>
                <div>
                  <form action='' className='formStay'>
                    <h4>Stay Booking</h4>
                    <div className='dateSection'>
                      <div className='col-md-3'>
                        <label htmlFor=''>Start Date - End Date</label>
                        <Calendar
                          value={dates}
                          onChange={(e) => setDates(e.value)}
                          selectionMode='range'
                          readOnlyInput
                          minDate={new Date()} // Set the minimum date to today
                        />
                      </div>
                    </div>
                    <div>
                      {is_slots ? (
                        <p
                          className='slotsAvailable'
                          style={{
                            backgroundColor:
                              stayavailableslotsdata?.slots > 0
                                ? '#107846'
                                : 'red',
                          }}
                        >
                          Rooms available : {''}
                          {stayavailableslotsdata?.slots <= 0
                            ? 'No rooms are available.'
                            : stayavailableslotsdata?.slots}
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className='row roomSection'>
                      <div className='col-md-3' style={{ padding: '0px' }}>
                        <label htmlFor=''>Number of room : </label>
                        <select
                          name='number_of_room'
                          id='number_of_room'
                          value={noofroom}
                          onChange={(e) => setNoOfroom(e.target.value)}
                        >
                          {listCount(activeStayId[0]?.no_of_rooms)}
                        </select>
                      </div>
                    </div>

                    <div className='row userDetailsSection mb-3'>
                      <div className='col-md-3'>
                        <label htmlFor=''>Number of Adult Count </label>
                        <input
                          type='text'
                          placeholder='Number of Adult Count'
                          value={noofadult_count}
                          onChange={(e) => setNoOfadult_count(e.target.value)}
                        />
                      </div>
                      <div className='col-md-3'>
                        <label htmlFor=''>Number of Child Count </label>
                        <input
                          type='text'
                          placeholder='Number of Child Count'
                          value={noofchild_count}
                          onChange={(e) => setNoOfchild_count(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='usercategorySection'>
                      <div>
                        <input
                          type='radio'
                          id='Indian'
                          name='user_category'
                          value='Indian'
                          onChange={(e) => setUser_category(e.target.value)}
                          checked={user_category === 'Indian'}
                        />
                        <label>Indian</label>
                        <input
                          type='radio'
                          id='Foreign'
                          name='user_category'
                          value='Foreign'
                          onChange={(e) => setUser_category(e.target.value)}
                          checked={user_category === 'Foreign'}
                        />
                        <label>Foreign</label>
                      </div>
                    </div>
                    <div className='row userDetailsSection'>
                      <div className='col-md-3'>
                        <input
                          type='text'
                          placeholder='Name'
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>

                      <div className='col-md-3 '>
                        <select
                          name='id_proof_type'
                          id='id_proof_type'
                          value={id_proof_type}
                          onChange={(e) => setId_proof_type(e.target.value)}
                        >
                          {getactiveidproofsdata?.map((ids, index) => (
                            <option key={index} value={ids.title}>
                              {ids.title}
                            </option>
                          ))}
                        </select>
                        {/* <input type="text" placeholder="id_proof_type" /> */}
                      </div>
                      <div className='col-md-3'>
                        <input
                          type='text'
                          placeholder='Id Proof Number'
                          value={id_proof_number}
                          onChange={(e) => setId_proof_number(e.target.value)}
                        />
                      </div>
                      <div className='col-md-3'>
                        <input
                          type='text'
                          placeholder='Insurance Number'
                          value={insurance_number}
                          onChange={(e) => setInsurance_number(e.target.value)}
                        />
                      </div>
                      <div className='col-md-3'>
                        <input
                          type='number'
                          placeholder='Phone Number'
                          value={phone_number}
                          className='number-input'
                          onChange={(e) => setPhone_number(e.target.value)}
                          style={{
                            WebkitAppearance: 'none',
                            appearance: 'none',
                            // Remove padding to make the arrow area smaller
                          }}
                          onWheel={(e) => e.preventDefault()}
                        />
                      </div>
                    </div>
                    <div className='submitBtn'>
                      <button
                        className='mainButton'
                        onClick={(e) => submitHandlers(e)}
                        disabled={loader}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
                <div className='PackageFacility'>
                  <h5>Facility</h5>
                  <div className='row'>
                    {facilities?.map((Facility, index) => (
                      <div className='col-md-3' key={index}>
                        <div className='FacilityItems'>
                          <span>{Facility}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                  <h5>Activities</h5>
                  <div className='row'>
                    {activities?.map((activities, index) => (
                      <div className='col-md-3' key={index}>
                        <div className='FacilityItems'>
                          <span>{activities}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <h4>Stay booking</h4>
                <div style={warningStyle}>
                  <p>The Booking can be done through offline only</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Stay
