import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import './event.css'
import { BaseUrl } from '../../../Services/baseUrl'
import { useNavigate } from 'react-router-dom'

export default function Event({ getactiveEventdata }) {
  const navigate = useNavigate()
  // Function to split the data into chunks of 3 items
  const [isHovered, setIsHovered] = useState(false)

  const handleHover = (hovering) => {
    setIsHovered(hovering)
  }
  const chunkArray = (arr, chunkSize) => {
    const chunks = []
    for (let i = 0; i < arr?.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize))
    }
    return chunks
  }

  // Split the getactiveEventdata into chunks of 3 items each
  const eventChunks = chunkArray(getactiveEventdata, 4)
  const toList = (id) => {
    navigate(`/eventdetails/${id}`)
  }
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isHovered) {
        // Auto play when not hovered
        // You can adjust the interval time as needed
        // For example, you mentioned an interval of 4000ms (4 seconds)
        // You can change it here.
        // Make sure it's the same as the Carousel's interval prop.
        // For example, for 4 seconds: 4000 milliseconds
        // Adjust it according to your requirements.
        // 1000ms = 1 second
      }
    }, 4000)

    return () => {
      clearInterval(intervalId)
    }
  }, [isHovered])
  return (
    <div className='maineventConatiner'>
      <div className='titleText'>
        <h1>
          What's<span>New</span>
        </h1>
      </div>
      <Carousel
        showThumbs={false}
        interval='4000'
        transitionTime='1000'
        showIndicators={false}
        showStatus={false}
        autoPlay={!isHovered}
        infiniteLoop={true}
      >
        {eventChunks.map((chunk, index) => (
          <div
            key={index}
            className='row carousel-card'
            onMouseEnter={() => handleHover(true)}
            onMouseLeave={() => handleHover(false)}
          >
            {chunk.map((event, i) => (
              <div key={i} className='col-md-3'>
                <div className='eventcardConatiner'>
                  <img src={`${BaseUrl}/${event.image1}`} alt='' />
                  <div className='titleSection'>
                    <h4>
                      {event.title.length > 25
                        ? event.title.substring(0, 25) + '...'
                        : event.title}
                    </h4>
                    <p>{event.startdate}</p>
                  </div>
                  <div
                    className='eventFooter'
                    onClick={() => toList(event._id)}
                  >
                    <p>View Details</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </Carousel>
    </div>
  )
}
