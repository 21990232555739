import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import deerIcon from "../../../Assets/Images/fillterGallery/deer.svg";
import { BaseUrl } from "../../../Services/baseUrl";

function FilterGallery({
  catId,
  getactivePackagesdata,
  getactivelocations,
  getactivestaydata,
  getactiveticketdata,
}) {
  const [locId, setLocId] = useState("");
  const getLocIdID = (index) => {
    if (!index) {
      setLocId(getactivelocations[0]?._id);
    } else {
      setLocId(getactivelocations[index]?._id);
    }
  };

  useEffect(() => {
    getLocIdID();
  }, [getactivelocations]);

  return (
    <div className="filterGallery homeGallery">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4">
            <div className="visiting-plans">
              <h1>
                Set<span>Your</span>
              </h1>
              <p>Visiting Plans</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="lineconatiner">
              <svg
                width="272"
                height="28"
                viewBox="0 0 272 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="258" cy="14" r="14" fill="#107846" />
                <path
                  d="M258 14L1 14"
                  stroke="#107846"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>
            </div>
          </div>
          <div className="col-md-4">
            <ul>
              {getactivelocations?.map((location, index) => (
                <li
                  className="nav-item"
                  onClick={() => getLocIdID(index)}
                  key={location._id}
                >
                  <a
                    className={`nav-link ${
                      location._id === locId ? `active` : ``
                    }`}
                    data-bs-toggle="pill"
                    href="#home"
                  >
                    <span>
                      {location?.title?.length > 60
                        ? location?.title.substring(0, 60) + "..."
                        : location?.title}
                    </span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="tab-content filtercardSection">
          <div id="home" className="tab-pane active">
            <div className="row">
              {getactivePackagesdata
                ?.filter(
                  (packagess) =>
                    packagess.cat_id === catId && packagess.loc_id === locId
                )
                ?.map((packages) => (
                  <div className="col-md-4" key={packages._id}>
                    <Link to={`/placeDetails/${packages._id}`}>
                      <div className="filterGalleryCard">
                        <div className="filterGalleryCardImg">
                          <img
                            src={`${BaseUrl}/${packages.image1}`}
                            alt="picc"
                          />
                        </div>
                        <div className="filterGalleryCardTxt">
                          <div className="txtBox">
                            <h5>{packages.package_name}</h5>
                            <div>
                              <Link to={`/placeDetails/${packages._id}`}>
                                View Details
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              {getactivestaydata
                ?.filter(
                  (stay) => stay.cat_id === catId && stay.location_id === locId
                )
                ?.map((stayData) => (
                  <div className="col-md-4" key={stayData._id}>
                    <div className="filterGalleryCard">
                      <div className="filterGalleryCardImg">
                        <img src={`${BaseUrl}/${stayData.image1}`} alt="picc" />
                      </div>
                      <div className="filterGalleryCardTxt">
                        <div className="txtBox">
                          <h5>{stayData.name}</h5>
                          <div>
                            <Link to={`/stay/${stayData._id}`}>
                              View Details
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {getactiveticketdata
                ?.filter(
                  (ticket) => ticket.cat_id === catId && ticket.loc_id === locId
                )
                ?.map((ticketdata) => (
                  <div className="col-md-4" key={ticketdata._id}>
                    <div className="filterGalleryCard">
                      <div className="filterGalleryCardImg">
                        <img
                          src={`${BaseUrl}/${ticketdata.image1}`}
                          alt="picc"
                        />
                      </div>
                      <div className="filterGalleryCardTxt">
                        <div className="txtBox">
                          <h5>{ticketdata.package_name}</h5>
                          <div>
                            <Link to={`/ticketBooking/${ticketdata._id}`}>
                              View Details
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilterGallery;
