import React, { useEffect, useMemo, useState } from 'react'
import './contactUs.css'
import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api'
import innerBanner from '../../../Assets/Images/inner-banner/inner-banner1.jpg'
import { getactivecontacts } from '../../../Services/BaseApiService'

function ContactUs() {
  const [mapData, setmapData] = useState()
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyC-gpUVv_8Wq5dCqNrSgaVBDc9FuoM30eg',
  })
  const center = useMemo(() => ({ lat: 8.55557, lng: 77.155291 }), [])
  useEffect(() => {
    getactivecontacts(setmapData)
  }, [])

  if (!isLoaded) {
  } else {
    return (
      <div className='contactUsWapper'>
        <div className='innerpage-banners'>
          <img src={innerBanner} alt='inner banner' />
          <h3 className='innerBanerTitle'>Contact Us</h3>
        </div>
        <div className='container'>
          <div className='row  align-items-center'>
            <div className='col-md-8'>
              <div className='contactMap'>
                <GoogleMap
                  center={center}
                  zoom={10}
                  mapContainerStyle={{ width: '100%', height: '100%' }}
                >
                  {mapData?.map((locations, index) => (
                    <Marker
                      key={index}
                      position={{
                        lat: parseFloat(locations.lat),
                        lng: parseFloat(locations.lon),
                      }}
                    />
                  ))}
                </GoogleMap>
              </div>
            </div>
            <div className='col-md-4'>
              {mapData?.map((loactions, index) => (
                <div className='contactTxt' key={index}>
                  <h5>{loactions.office_name}</h5>
                  <p> {loactions.address}</p>
                  <p>Ph : {loactions.phone}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ContactUs
