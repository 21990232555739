import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getPaymentStatus } from '../../Services/BaseApiPostService'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { PaymentUrl } from '../../Services/baseUrl'
import Loading from '../Layouts/Loading'

export default function Payment() {
  const [isPayment, setIsPayment] = useState(false)
  const [showLoader, setShowLoader] = useState(true)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const { id } = useParams()
  const userInfo = JSON.parse(localStorage.getItem('user'))
  const navigate = useNavigate()
  const data = {
    booking_id: id,
  }
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    sessionStorage.removeItem("tableData");
    sessionStorage.removeItem("dateDeatils");
    sessionStorage.removeItem("bookingSummaryId");
    scrollToTop() // Scroll to top when the component mounts
  }, [])
  const checkPaymentStatus = useCallback(() => {
    getPaymentStatus(data)
      .then((res) => {
        if (res.data.status) {
          setIsPayment(true)
          setShowLoader(false) // Hide loader when payment status is successful
          navigate(`/myBookings`)
          setShowSuccessMessage(true)
        } else {
          setShowLoader(false) // Hide loader when payment status is failed
        }
      })
      .catch((err) => {
        setIsPayment(true)
        setShowLoader(false) // Hide loader on error
      })
  }, [data, navigate])

  useEffect(() => {
    const loaderTimeout = setTimeout(() => {
      setShowLoader(false) // Hide loader after 5 seconds
    }, 5000)

    const intervalId = setInterval(() => {
      if (!isPayment) {
        checkPaymentStatus()
      }
    }, 2000)

    return () => {
      clearInterval(intervalId)
      clearTimeout(loaderTimeout) // Clear timeout on unmount
    }
  }, [isPayment, checkPaymentStatus, id, navigate])

  return (
    <>
      <style>
        {`
  .topMessage {
    position: fixed;
    top: 500px;
    left: 0;
    width: 100%;
    background-color: #fff; /* Optional: Set the background color */
    z-index: 9999; /* Optional: Set the z-index to ensure it's above other content */
    padding: 10px; /* Optional: Add some padding */
    text-align: center; /* Optional: Center the text */
  }
  `}
      </style>
      {showLoader && <Loading />}
      {showSuccessMessage && (
        <p className='topMessage' style={{ backgroundColor: '#6EBD6E' }}>
          Payment successful! Redirecting to My Bookings...
        </p>
      )}
      {showLoader === true ? (
        <p className='topMessage'>
          You will be redirected to the payment gateway. It might take a few
          seconds.
        </p>
      ) : (
        ''
      )}

      <iframe
        src={`${PaymentUrl}/loadingpayment.php?_id=${id}&token=${userInfo.session}`}
      ></iframe>
    </>
  )
}
