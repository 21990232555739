import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div className='footerWapper'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
            <p> 2024 © Trivandrum Wildlife Division - All Rights Reserved. </p>
          </div>
          <div className='col-md-6'>
            <nav className='footerNav'>
              <ul className='nav'>
                <li>
                  <Link to='/commonbased/terms_conditions'>
                    Terms & Conditions
                  </Link>
                </li>

                <li>
                  <Link to='/commonbased/cancellation_policy'>
                    Booking & Cancellation Policy
                  </Link>
                </li>
                <li>
                  <Link to='/commonbased/privacy_policy'>Privacy Policy</Link>
                </li>
                <li>
                  <Link to='/dodont'>Dos And Don'ts</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
