import React, { useEffect, useState } from "react";
import banner from "../../Assets/Images/inner-banner/inner-banner.jpg";
import createDOMPurify from "dompurify";
import { getcommons } from "../../Services/BaseApiPostService";

export const DoDont = () => {
  const [dodata, setdodata] = useState([]);
  const [dont, setdont] = useState([]);
  const DOMPurify = createDOMPurify(window);
  const dataFormdo = {
    type: "do",
  };
  const dataFormdont = {
    type: "dont",
  };

  useEffect(() => {
    getcommons(dataFormdo, setdodata);
    getcommons(dataFormdont, setdont);
  }, []);

  return (
    <div className="commonBasedConatiner">
      {dodata.length === 0 ? (
        <div
        style={{
          position: "absolute",
          zIndex: "500",
          top: "0px",
          right: "0px",
          width: "100%",
        }}
      >
        <div className="loadingMainSection" style={{ height: "100vh" }}>
          <div className="loader"></div>
        </div>
      </div>
      ) : (
        <>
          <div className="commonBasedConatinerbanner">
            <img src={banner} alt="pic" />
            <h3>Do & Dont</h3>
          </div>
          {dodata?.data.map((data) => (
            <div className="commonBasedConatinercontent" key={data._id}>
              <h5>{data.name}</h5>
              <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(data.content),
                }}
              />
            </div>
          ))}
          {dont?.data?.map((data) => (
            <div className="commonBasedConatinercontent" key={data._id}>
              <h5>{data.name}</h5>
              <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(data.content),
                }}
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
};
