import axios from "axios";
import { BaseUrl } from "./baseUrl";
const userInfo = JSON.parse(localStorage.getItem("user"));

const axiosCall = async (url, setData) => {
  await axios
    .get(`${BaseUrl}/${url}`)
    .then((res) => {
      setData(res.data.data)
    })
    .catch((err) => console.log(err));
};
const axiosGetCallauth = async (url) => {
  const res = await axios.get(`${BaseUrl}/${url}`, {
    headers: {
      Authorization: "Bearer " + userInfo?.session,
    },
  });

  return res;
};

export const getactivePackages = async (setgetactivePackages) => {
  await axiosCall("web/get_active_packages", setgetactivePackages);
};
export const getactiveEvent = async (setgetEvent) => {
  await axiosCall("web/get_active_event", setgetEvent);
};
export const getactivelocations = async (setgetactivelocations) => {
  await axiosCall("web/get_active_locations", setgetactivelocations);
};
export const getactivesubpackages = async (setgetactivesubpackages) => {
  await axiosCall("web/get_active_subpackages", setgetactivesubpackages);
};
export const getactivecategories = async (setgetactivecategories) => {
  await axiosCall("web/get_active_categories", setgetactivecategories);
};
export const getactivestay = async (setgetactivestay) => {
  await axiosCall("web/get_active_stays", setgetactivestay);
};
export const getactiveTicket = async (setgetactiveTicket) => {
  await axiosCall("web/get_active_ticket_packages", setgetactiveTicket);
};
export const getactiveidproofs = async (setgetactiveidproofs) => {
  await axiosCall("web/get_active_idproofs", setgetactiveidproofs);
};
export const getactivecontacts = async (setmapData) => {
  await axiosCall("web/get_active_contacts", setmapData);
};
export const getactivesliders = async (setSliders) => {
  await axiosCall("web/get_active_sliders", setSliders);
};

export const getBookingHistoryCall = async () => {
  const res = await axiosGetCallauth("admin/booking/get_booking_history");

  return res;
};
