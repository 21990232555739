import React, { useState } from 'react'
import innerBanner from '../../../Assets/Images/inner-banner/inner-banner1.jpg'
import './About.css'
import { BaseUrl } from '../../../Services/baseUrl'
import Loading from '../../Layouts/Loading'
import createDOMPurify from 'dompurify'

function About({ getactivelocationsdata }) {
  const DOMPurify = createDOMPurify(window)
  const [showFullDescription, setShowFullDescription] = useState(false)

  return (
    <>
      {getactivelocationsdata.length > 0 ? (
        <div className='AboutWrapper'>
          <div className='innerpage-banners'>
            <img src={innerBanner} alt='inner banner' />
            <h3 className='innerBanerTitle'>About Reserve</h3>
          </div>
          <div className='Aboutcard'>
            <div className='container'>
              {getactivelocationsdata?.map((about, index) => (
                <div className='AboutcardInner' key={about._id}>
                  <div
                    className={`row align-items-center aboutCardSection ${
                      index % 2 === 0 ? 'aboutCardSectionRight' : ''
                    }`}
                  >
                    <div className='col-md-6'>
                      <div className='cardImg'>
                        <img
                          src={`${BaseUrl}/${about.image1}`}
                          alt={about.title}
                        />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='cardTtx'>
                        <h2>{about.title}</h2>
                        <div>
                          {showFullDescription
                            ? about.description
                            : `${about.description.slice(0, 680)}...`}
                          {about.description.length > 680 && (
                            <span
                              className='read-more-link'
                              onClick={() =>
                                setShowFullDescription(!showFullDescription)
                              }
                              style={{
                                color: '#007bff', // Change the color to your preference
                                cursor: 'pointer',
                                marginTop: '10px',
                                display: 'inline-block',
                              }}
                            >
                              {showFullDescription ? 'Read Less' : 'Read More'}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  )
}

export default About
