import { Link } from 'react-router-dom'
import './placeListing.css'
import innerBanner from '../../../Assets/Images/inner-banner/inner-banner1.jpg'
import { useState } from 'react'
import { useEffect } from 'react'
import { BaseUrl } from '../../../Services/baseUrl'
import createDOMPurify from 'dompurify'
import Loading from '../../Layouts/Loading'

function PlaceListing({
  getactivecategoriesdata,
  getactivelocationsdata,
  getactivePackagesdata,
  getactiveticketdata,
  getactivestaydata,
}) {
  const [locId, setLocId] = useState('')
  const [catId, setcatId] = useState('')

  const getcatID = (index) => {
    if (!index) {
      setcatId(getactivecategoriesdata[0]?._id)
    } else {
      setcatId(getactivecategoriesdata[index]?._id)
    }
  }

  const getLocIdID = (index) => {
    if (!index) {
      setLocId(getactivelocationsdata[0]?._id)
    } else {
      setLocId(getactivelocationsdata[index]?._id)
    }
  }

  useEffect(() => {
    getLocIdID()
    getcatID()
  }, [getactivelocationsdata])

  useEffect(() => {
    sessionStorage.removeItem("tableData");
    sessionStorage.removeItem("dateDeatils");
    sessionStorage.removeItem("bookingSummaryId");
  }, [])

  const DOMPurify = createDOMPurify(window)

  return (
    <>
      {getactivecategoriesdata.length > 0 ? (
        <div className='filterGallery PlaceListing'>
          <div className='innerpage-banners'>
            <img src={innerBanner} alt='inner banner' />
            <h3 className='innerBanerTitle'>Set Your Plans</h3>
          </div>
          <div className='container'>
            <div className='activeCategoriesBtn'>
              {getactivecategoriesdata?.map((categories, index) => (
                <div
                  key={index}
                  // className={`categoriesCard ${
                  //   index % 2 == 0 ? `colum-sec` : `colum-third`
                  // }`}
                  className={`categoriesCard ${
                    categories._id === catId ? `colum-sec` : ``
                  }`}
                >
                  {/* className={`btn ${index === 0 ? `selectedBtn` : ``}`} */}
                  <button className='btn' onClick={() => getcatID(index)}>
                    <span className=''>{categories.title}</span>
                  </button>
                </div>
              ))}
            </div>
            {/* <ul className="nav nav-pills" role="tablist">
          <div className="navSection">
            {getactivelocationsdata?.map((loaction, index) => (
              <li
                className="nav-item"
                onClick={() => getLocIdID(index)}
                key={loaction._id}
              >
                <a
                  className={`nav-link ${
                    loaction._id === locId ? `active` : ``
                  }`}
                  data-bs-toggle="pill"
                  href="#home"
                >
                  <img src={deerIcon} alt="icon" />
                  <span>{loaction.title}</span>
                </a>
              </li>
            ))}
          </div>
        </ul> */}

            <div className='tab-content'>
              <div id='home' className='tab-pane active'>
                <div className='row'>
                  {getactivePackagesdata
                    ?.filter(
                      (packagess) => packagess.cat_id === catId
                      // && packagess.loc_id === locId
                    )
                    ?.map((pckags) => (
                      <div className='col-md-4' key={pckags._id}>
                        <div className='PlaceListingCard'>
                          <div className='PlaceListingCardImg'>
                            <img src={`${BaseUrl}/${pckags.image1}`} alt='' />
                          </div>
                          <div className='PlaceListingCardTxt'>
                            <div className='PlaceListingCardTxtBox'>
                              <h5>{pckags.package_name}</h5>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(
                                    pckags.des.length > 250
                                      ? pckags.des.substring(0, 250) + '...'
                                      : pckags.des
                                  ),
                                }}
                              />

                              <Link to={`/placeDetails/${pckags._id}`}>
                                <button className='mainButton'>
                                  View Details
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  {getactivestaydata
                    ?.filter(
                      (packagess) => packagess.cat_id === catId
                      // && packagess.loc_id === locId
                    )
                    ?.map((pckags) => (
                      <div className='col-md-4' key={pckags._id}>
                        <div className='PlaceListingCard'>
                          <div className='PlaceListingCardImg'>
                            <img src={`${BaseUrl}/${pckags.image1}`} alt='' />
                          </div>
                          <div className='PlaceListingCardTxt'>
                            <div className='PlaceListingCardTxtBox'>
                              <h5>{pckags.name}</h5>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(
                                    pckags.des.length > 250
                                      ? pckags.des.substring(0, 250) + '...'
                                      : pckags.des
                                  ),
                                }}
                              />

                              <Link to={`/stay/${pckags._id}`}>
                                <button className='mainButton'>
                                  View Details
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  {getactiveticketdata
                    ?.filter(
                      (packagess) => packagess.cat_id === catId
                      // && packagess.loc_id === locId
                    )
                    ?.map((pckags) => (
                      <div className='col-md-4' key={pckags._id}>
                        <div className='PlaceListingCard'>
                          <div className='PlaceListingCardImg'>
                            <img src={`${BaseUrl}/${pckags.image1}`} alt='' />
                          </div>
                          <div className='PlaceListingCardTxt'>
                            <div className='PlaceListingCardTxtBox'>
                              <h5>{pckags.package_name}</h5>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(
                                    pckags.des.length > 250
                                      ? pckags.des.substring(0, 250) + '...'
                                      : pckags.des
                                  ),
                                }}
                              />

                              <Link to={`/ticketBooking/${pckags._id}`}>
                                <button className='mainButton'>
                                  View Details
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  )
}

export default PlaceListing
