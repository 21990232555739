import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import banner from "../../Assets/Images/inner-banner/inner-banner.jpg";
import "./commonBased.scss";
import createDOMPurify from "dompurify";
import { getcommons } from "../../Services/BaseApiPostService";

function CommonBased() {
  const [CommonBased, setCommonBased] = useState([]);
  const params = useParams();
  const { id } = params;
  const DOMPurify = createDOMPurify(window);
  const dataForm = {
    type: id,
  };

  useEffect(() => {
    getcommons(dataForm, setCommonBased);
  }, [id]);

  return (
    <div className="commonBasedConatiner">
      {CommonBased?.length === 0 ? (
       <div
       style={{
         position: "absolute",
         zIndex: "500",
         top: "0px",
         right: "0px",
         width: "100%",
       }}
     >
       <div className="loadingMainSection" style={{ height: "100vh" }}>
         <div className="loader"></div>
       </div>
     </div>
      ) : (
        CommonBased?.data?.map((data) => (
          <div key={data._id}>
            <div className="commonBasedConatinerbanner">
              <img src={banner} alt="pic" />
              <h3>{data.name}</h3>
            </div>
            <div className="commonBasedConatinercontent">
              <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(data.content),
                }}
              />
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default CommonBased;
