import React from 'react'
import { useParams } from 'react-router-dom'
import innerBanner from '../../../Assets/Images/inner-banner/inner-banner1.jpg'
import createDOMPurify from 'dompurify'
import ImageGallery from 'react-image-gallery'
import Loading from '../../Layouts/Loading'
import { BaseUrl } from '../../../Services/baseUrl'
import { useEffect } from 'react'
import { useState } from 'react'

export default function EventDec({ getactiveEventdata }) {
  // const EventsData = getactiveEventdata

  const [eventData, seteventData] = useState([])
  const params = useParams()
  const { id } = params
  const DOMPurify = createDOMPurify(window)

  const images = [
    {
      original: `${BaseUrl}/${eventData[0]?.image1}`,
      thumbnail: `${BaseUrl}/${eventData[0]?.image1}`,
    },
    {
      original: `${BaseUrl}/${eventData[0]?.image2}`,
      thumbnail: `${BaseUrl}/${eventData[0]?.image2}`,
    },
    {
      original: `${BaseUrl}/${eventData[0]?.image3}`,
      thumbnail: `${BaseUrl}/${eventData[0]?.image3}`,
    },
    {
      original: `${BaseUrl}/${eventData[0]?.image4}`,
      thumbnail: `${BaseUrl}/${eventData[0]?.image4}`,
    },
    {
      original: `${BaseUrl}/${eventData[0]?.image5}`,
      thumbnail: `${BaseUrl}/${eventData[0]?.image5}`,
    },
  ]
  useEffect(() => {
    const filterEvent = () => {
      if (id && getactiveEventdata.length > 0) {
        const result = getactiveEventdata.filter((item) => item._id === id)
        seteventData(result)
      }
    }

    filterEvent()
  }, [id, getactiveEventdata])
  return (
    <>
      {getactiveEventdata.length > 0 ? (
        <div className='placeDetails'>
          <div className='innerpage-banners'>
            <img src={innerBanner} alt='inner banner' />
          </div>
          <div className='container'>
            <div className='placeDetailsinner'>
              <div className='placeDetailsinnerTxt'>
                <h1>{eventData[0]?.title}</h1>
              </div>
              <div className='row '>
                <div className='col-md-6'>
                  <div className='placeDetailsinnerTxtbox'>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(eventData[0]?.description),
                      }}
                    />
                    <hr />
                    <p>Start date : {eventData[0]?.startdate}</p>
                    <p>End date : {eventData[0]?.enddate}</p>
                  </div>
                </div>
                <div className='col-md-6'>
                  <ImageGallery items={images} lazyLoad={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  )
}
