import axios from 'axios'
import { toast } from 'react-toastify'
import { BaseUrl } from '../../Services/baseUrl'

const emailVrf = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
const PhoneNumber = /^[0-9]{10}$/g
const userInfo = JSON.parse(localStorage.getItem('user'))

export const loginHandler = async (
  e,
  email,
  password,
  fromData,
  setloginPopup,
  setisLoading
) => {
  e.preventDefault()
  if (email.trim() === '') {
    toast.error('Please enter email')
  } else if (password.trim() === '') {
    toast.error('Please enter password')
  } else if (!emailVrf.test(email)) {
    toast.error('You have entered an invalid email address!')
  } else {
    setisLoading(true)
    const res = await axios.post(`${BaseUrl}/auth/auth/login`, fromData)

    if (res.data.status) {
      localStorage.setItem('user', JSON.stringify(res.data.data))
      // toast.success(res.data.message);
      setloginPopup(false)
      setisLoading(false)
      window.location.reload(false)
    } else {
      setisLoading(false)
      toast.error(res.data.message)
    }
  }
}

export const submitHandler = async (
  e,
  email,
  password,
  cnpassword,
  fromData,
  setOTP,
  name,
  phone,
  setisLoading
) => {
  e.preventDefault()
  if (name.trim() === '') {
    toast.error('Please enter name')
  } else if (phone.trim() === '') {
    toast.error('Please enter phone number')
  } else if (email.trim() === '') {
    toast.error('Please enter email')
  } else if (password.trim() === '') {
    toast.error('Please enter password')
  } else if (cnpassword.trim() === '') {
    toast.error('Please enter confirm password')
  } else if (!emailVrf.test(email)) {
    toast.error('You have entered an invalid email address!')
  } else if (!PhoneNumber.test(phone)) {
    toast.error('You have entered an invalid phone number!')
  } else if (password.trim() !== cnpassword.trim()) {
    toast.error('Password does not match')
  } else {
    setisLoading(true)
    const res = await axios.post(`${BaseUrl}/auth/auth/signup`, fromData)

    if (res.data.status) {
      toast.success(res.data.message)
      setOTP(true)
      setisLoading(false)
    } else {
      toast.error(res.data.message)
      setisLoading(false)
    }
  }
}

export const OTPHandler = async (
  e,
  email,
  otpdata,
  setOTP,
  setsignupPopup,
  setisLoading
) => {
  e.preventDefault()
  setisLoading(true)
  const data = {
    email: email,
    otp: otpdata,
  }
  const res = await axios.post(`${BaseUrl}/auth/auth/verifyotp`, data)

  if (res.data.status) {
    toast.success(res.data.message)
    setOTP(false)
    setsignupPopup(false)
    setisLoading(false)
  } else {
    toast.error(res.data.message)
    setisLoading(false)
  }
}

export const forgotPassword = async (
  e,
  email,
  setisforgotPasswordOtp,
  setloginPopup,
  setOTP,
  setisLoading
) => {
  e.preventDefault()

  if (email.trim() === '') {
    toast.error('Please enter email')
  } else if (!emailVrf.test(email)) {
    toast.error('You have entered an invalid email address!')
  } else {
    setisLoading(true)
    const data = {
      email: email,
    }

    const res = await axios.post(`${BaseUrl}/auth/auth/forgetpassword`, data)
    if (res.data.status) {
      toast.success(res.data.message)
      setisforgotPasswordOtp(true)
      setOTP(true)
      setloginPopup(false)
      setisLoading(false)
    } else {
      toast.error(res.data.message)
      setisLoading(false)
    }
  }
}

export const forgotPasswordOTPHandler = async (
  e,
  email,
  password,
  otpdata,
  setisforgotPasswordOtp,
  setisforgotPassword,
  setisLoading,
  setIsShowConfirmPassowrd
) => {
  e.preventDefault()
  setisLoading(true)
  const data = {
    email: email,
    password: password,
    otp: otpdata,
  }
  const res = await axios.post(`${BaseUrl}/auth/auth/updatepassword`, data)

  if (res.data.status) {
    toast.success(res.data.message)
    setisforgotPasswordOtp(false)
    setisforgotPassword(false)
    setisLoading(false)
    setIsShowConfirmPassowrd(false)
  } else {
    toast.error(res.data.message)
    setisLoading(false)
  }
}

export const getuserdetails = async (setuserDetail) => {
  const res = await axios.get(`${BaseUrl}/admin/users/getProfileDetails`, {
    headers: {
      Authorization: 'Bearer ' + userInfo?.session,
    },
  })
  // .then((res) => setuserDetail(res.data.data))
  // .catch((err) => console.log(err));
  if (res.data.status) {
    setuserDetail(res.data.data)
  } else {
    // userLogout();
  }
}

export const changePassword = async (
  e,
  email,
  password,
  oldPassword,
  setisLoading
) => {
  e.preventDefault()
  setisLoading(true)
  const data = {
    email: JSON.parse(localStorage.getItem('user')).email,
    old_password: oldPassword,
    password: password,
  }
  const res = await axios.post(
    `${BaseUrl}/auth/auth/user_change_password`,
    data
  )

  if (res.data.status) {
    toast.success(res.data.message)

    setisLoading(false)
  } else {
    toast.error(res.data.message)
    setisLoading(false)
  }
}

export const checkUserLoginStatus = async () => {
  let userData = JSON.parse(localStorage.getItem('user'))
  const data = {
    email: userData.email,
  }

  const res = await axios.post(`${BaseUrl}/web/check_active_user`, data)
  if (res.data.status) {
  } else {
    userLogout()
  }
}
export const userLogout = () => {
  window.location.reload(false)
  localStorage.clear()
  window.location.href = '/'
}
