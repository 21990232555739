import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './Components/Pages/Home/Home'
import About from './Components/Pages/About/About'
import ContactUs from './Components/Pages/contactUs/ContactUs'
import PlaceListing from './Components/Pages/placeListing/PlaceListing'
import PlaceDetails from './Components/Pages/placeDetails/placeDetails'
import Booking from './Components/Pages/booking/Booking'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { useEffect, useState } from 'react'
import Header from './Components/Layouts/Header'
import Footer from './Components/Layouts/Footer'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import CommonBased from './Components/Layouts/CommonBased'
import Search from './Components/Pages/Search/Search'
import { DoDont } from './Components/Layouts/DoDont'
import Stay from './Components/Pages/Stay/Stay'
import BookingSummary from './Components/Pages/booking/BookingSummary'
import { BookingHistory } from './Components/Pages/booking/BookingHistory'
import { TicketBooking } from './Components/Pages/booking/TicketBooking'
import { Profile } from './Components/Pages/Profile/Profile'
import {
  getactivecategories,
  getactiveEvent,
  getactivelocations,
  getactivePackages,
  getactivestay,
  getactivesubpackages,
  getactiveTicket,
} from './Services/BaseApiService'
import { postIdSection } from './Services/BaseApiPostService'
import Payment from './Components/component/Payment'
import { scrollToMostTop, scrollToTop } from './Services/CommonService'
import EventDetails from './Components/Pages/Event/EventDetails'
import EventDec from './Components/Pages/Event/EventDec'
import { checkUserLoginStatus } from './Components/Layouts/UserService'

function App() {
  const [getactivePackagesdata, setgetactivePackages] = useState([])
  const [getactivelocationsdata, setgetactivelocations] = useState([])
  const [getactivesubpackagesdata, setgetactivesubpackages] = useState([])
  const [getactivecategoriesdata, setgetactivecategories] = useState([])
  const [getactivestaydata, setgetactivestay] = useState([])
  const [getactiveticketdata, setgetactiveTicket] = useState([])
  const [getactiveEventdata, setgetactiveEventdata] = useState([])
  const [loginPopup, setloginPopup] = useState(false)
  const [dropdownMenu, setdropdownMenu] = useState(false)
  const [bookingSummaryId, setbookingSummaryId] = useState('')

  const idDrop = () => {
    if (dropdownMenu) {
      setdropdownMenu(false)
    }
  }

  useEffect(() => {
    getactivePackages(setgetactivePackages)
    getactivelocations(setgetactivelocations)
    getactivesubpackages(setgetactivesubpackages)
    getactivecategories(setgetactivecategories)
    getactivestay(setgetactivestay)
    getactiveTicket(setgetactiveTicket)
    getactiveEvent(setgetactiveEventdata)
    const data = {
      id: '106',
    }
    postIdSection()
    scrollToTop()
  }, [])

  return (
    <div className='App' onClick={() => idDrop()}>
      <BrowserRouter>
        <ToastContainer />
        <Header
          loginPopup={loginPopup}
          setloginPopup={setloginPopup}
          setdropdownMenu={setdropdownMenu}
          dropdownMenu={dropdownMenu}
        />
        <Routes>
          <Route
            path='/'
            element={
              <Home
                getactivePackagesdata={getactivePackagesdata}
                getactivelocationsdata={getactivelocationsdata}
                getactivecategoriesdata={getactivecategoriesdata}
                getactivestaydata={getactivestaydata}
                getactiveticketdata={getactiveticketdata}
                getactiveEventdata={getactiveEventdata}
              />
            }
          />
          <Route
            path='/placeListing'
            element={
              <PlaceListing
                getactivePackagesdata={getactivePackagesdata}
                getactivelocationsdata={getactivelocationsdata}
                getactivecategoriesdata={getactivecategoriesdata}
                getactivestaydata={getactivestaydata}
                getactiveticketdata={getactiveticketdata}
              />
            }
          />
          <Route
            path='/eventlisting'
            element={<EventDetails getactiveEventdata={getactiveEventdata} />}
          />
          <Route
            path='/about'
            element={<About getactivelocationsdata={getactivelocationsdata} />}
          />
          <Route path='/contact' element={<ContactUs />} />
          <Route path='/profile' element={<Profile />} />
          <Route
            path='/myBookings'
            element={<BookingHistory setloginPopup={setloginPopup} />}
          />
          <Route path='/myOrders' element={<myOrders />} />
          <Route path='/commonbased/:id' element={<CommonBased />} />
          <Route path='/dodont' element={<DoDont />} />
          <Route path='/search' element={<Search />} />
          <Route
            path='/placeDetails/:id'
            element={
              <PlaceDetails
                getactivestaydata={getactivestaydata}
                getactivesubpackagesdata={getactivesubpackagesdata}
                getactivePackagesdata={getactivePackagesdata}
              />
            }
          />
          <Route
            path='/eventdetails/:id'
            element={<EventDec getactiveEventdata={getactiveEventdata} />}
          />
          <Route
            path='/stay/:id'
            element={
              <Stay
                setbookingSummaryId={setbookingSummaryId}
                loginPopup={loginPopup}
                setloginPopup={setloginPopup}
                getactivesubpackagesdata={getactivesubpackagesdata}
                getactivePackagesdata={getactivePackagesdata}
                getactivestaydata={getactivestaydata}
              />
            }
          />
          <Route
            path='/ticketBooking/:id'
            element={
              <TicketBooking
                loginPopup={loginPopup}
                setloginPopup={setloginPopup}
                getactivesubpackagesdata={getactivesubpackagesdata}
                getactivePackagesdata={getactivePackagesdata}
                getactiveticketdata={getactiveticketdata}
                setbookingSummaryId={setbookingSummaryId}
              />
            }
          />
          <Route
            path='/booking/:id'
            element={
              <Booking
                loginPopup={loginPopup}
                setloginPopup={setloginPopup}
                setbookingSummaryId={setbookingSummaryId}
                getactivesubpackagesdata={getactivesubpackagesdata}
              />
            }
          />
          <Route
            path='/bookingSummary'
            element={
              <BookingSummary
                bookingSummaryId={bookingSummaryId}
                getactivestaydata={getactivestaydata}
              />
            }
          />
          <Route path='/paymentGateway/:id' element={<Payment />} />
        </Routes>
        <Footer />
      </BrowserRouter>
      {/* <Layouts /> */}
    </div>
  )
}

export default App
