import React, { useRef, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import innerBanner from '../../../Assets/Images/inner-banner/inner-banner.jpg'
import ImageGallery from 'react-image-gallery'
import createDOMPurify from 'dompurify'
import { BaseUrl } from '../../../Services/baseUrl'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { orderPayment } from './orderPayment'
import { useReactToPrint } from 'react-to-print'
import { PrintScreen } from '../printScreen/PrintScreen'

import {
  getTicketBookingSerivce,
  getTicketBookingSummarySerivce,
} from '../../../Services/BaseApiPostService'
import { userLogout } from '../../Layouts/UserService'
import { convert } from '../../../Services/CommonService'
import moment from 'moment'
const warningStyle = {
  padding: '10px',
  color: '#ff0000',
  // fontWeight: 'bold',
}
export const TicketBooking = ({
  getactiveticketdata,
  getactivesubpackagesdata,
  setbookingSummaryId,
  setloginPopup,
}) => {
  const params = useParams()
  const { id } = params

  const activeTcktId = getactiveticketdata?.filter((Tckt) => Tckt._id === id)
  const subPackage = getactivesubpackagesdata?.filter(
    (sub) => sub.pkg_id === id
  )
  var facilities = activeTcktId[0]?.facilities?.split(',')
  var activities = activeTcktId[0]?.activities?.split(',')
  const DOMPurify = createDOMPurify(window)
  let navigate = useNavigate()
  const [bookingSummaryinfo, setbookingSummaryinfo] = useState({})
  const [date, setDate] = useState('')
  const [Loading, setLoading] = useState(false)
  const [isPaid, setisPaid] = useState(false)
  const [islodaing, setislodaing] = useState(false)
  const [user_category, setUser_category] = useState('Indian')
  const [noofadult_count, setNoOfadult_count] = useState('1')
  const [noofchild_count, setNoOfchild_count] = useState('0')
  const [noofchild_count_range, setNoOfchild_count_range] = useState('0')
  const userInfo = JSON.parse(localStorage.getItem('user'))
  const images = [
    {
      original: `${BaseUrl}/${activeTcktId[0]?.image1}`,
      thumbnail: `${BaseUrl}/${activeTcktId[0]?.image1}`,
    },
    {
      original: `${BaseUrl}/${activeTcktId[0]?.image2}`,
      thumbnail: `${BaseUrl}/${activeTcktId[0]?.image2}`,
    },
    {
      original: `${BaseUrl}/${activeTcktId[0]?.image3}`,
      thumbnail: `${BaseUrl}/${activeTcktId[0]?.image3}`,
    },
    {
      original: `${BaseUrl}/${activeTcktId[0]?.image4}`,
      thumbnail: `${BaseUrl}/${activeTcktId[0]?.image4}`,
    },
    {
      original: `${BaseUrl}/${activeTcktId[0]?.image5}`,
      thumbnail: `${BaseUrl}/${activeTcktId[0]?.image5}`,
    },
  ]
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'TWD',
  })

  const getCurrentDate = () => {
    if (!userInfo) {
      setloginPopup(true)
    }
    const today = new Date()
    const year = today.getFullYear()
    let month = today.getMonth() + 1
    let day = today.getDate()

    // Format month and day as two digits if they are less than 10
    if (month < 10) {
      month = `0${month}`
    }
    if (day < 10) {
      day = `0${day}`
    }

    return `${year}-${month}-${day}`
  }
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToTop()
    const storedData = sessionStorage.getItem('tableData')
    if (storedData) {
      const fData = JSON.parse(sessionStorage.getItem('tableData'))
      const inputDate = fData.date
      const parts = inputDate.split('-')
      const formattedDate = parts[2] + '-' + parts[1] + '-' + parts[0]
      setUser_category(fData.user_category)
      setDate(formattedDate)
      setNoOfadult_count(fData.adult_count)
      setNoOfchild_count(fData.child_count)
      setNoOfchild_count_range(fData.child_count_range)
    }
    // Scroll to top when the component mounts
    // Attach event listener to handle page leave
    window.addEventListener('beforeunload', handlePageLeave)

    return () => {
      // Remove event listener on component unmount
      window.removeEventListener('beforeunload', handlePageLeave)
    }
  }, [])
  const handlePageLeave = () => {
    // Clear session storage when leaving the page (e.g., refreshing)
    sessionStorage.removeItem('tableData')
  }

  const getBookingSummary = async (id) => {
    const dataForm = {
      id: id,
    }
    const res = await getTicketBookingSummarySerivce(dataForm)

    if (res.data.status) {
      setbookingSummaryinfo(res.data.data)
      // setbookingSummaryId(res.data.data)
      // navigate(`/bookingSummary`);
    } else {
      if (res.data.message === 'Invalid token') {
        userLogout()
      }
    }
  }

  const submitHandlers = async (e) => {
    e.preventDefault()
    sessionStorage.removeItem('tableData')
    if (noofadult_count.trim() === '' || noofadult_count.trim() === '0') {
      toast.error('please add an adult')
    } else if (noofchild_count.trim() === '') {
      toast.error('child count cant be null')
    } else if (noofadult_count < 1) {
      toast.error('Please add at least one adult')
    } else if (noofchild_count < 0) {
      toast.error('Invalid number of children')
    } else if (noofchild_count_range < 0) {
      toast.error('Invalid number of children ')
    } else if (noofchild_count_range.trim() === '') {
      toast.error('child count cant be null')
    } else if (date.trim() === '') {
      toast.error('please select a date')
    } else {
      // setLoading(true);
      var amount = 0
      var adult_count = 0
      var child_count = 0
      var child_count_range = 0 //child count age range between 5-13
      if (user_category === 'Indian') {
        adult_count = noofadult_count
        child_count = noofchild_count
        child_count_range = noofchild_count_range

        if (subPackage[0].min_person_allowed < noofadult_count) {
          let extra_person = noofadult_count - subPackage[0].min_person_allowed
          let extraperson_totalamount =
            extra_person * subPackage[0].extra_person_indian_amount

          amount = parseFloat(subPackage[0].indian_adult) * noofadult_count
          amount = amount + child_count * parseFloat(subPackage[0].indian_child)
          amount =
            amount +
            child_count_range * parseFloat(subPackage[0].indian_child_agerange)
        } else {
          amount =
            adult_count *
            subPackage[0].min_person_allowed *
            parseFloat(subPackage[0].indian_adult)
          amount = amount + child_count * parseFloat(subPackage[0].indian_child)
          amount =
            amount +
            child_count_range * parseFloat(subPackage[0].indian_child_agerange)
        }
        //}
      } else {
        adult_count = noofadult_count
        child_count = noofchild_count
        child_count_range = noofchild_count_range
        // if (subPackage[0].per_person_flag === true) {
        //   amount = parseFloat(subPackage[0].foreign_adult)
        // } else {

        if (subPackage[0].min_person_allowed < noofadult_count) {
          let extra_person_foreign =
            noofadult_count - subPackage[0].min_person_allowed
          let extrapersonforeign_totalamount =
            extra_person_foreign * subPackage[0].extra_person_foreign_amount

          // amount =
          //   parseFloat(subPackage[0].foreign_adult) *
          //     subPackage[0].min_person_allowed +
          //   extrapersonforeign_totalamount
          amount = parseFloat(subPackage[0].foreign_adult) * noofadult_count
          amount =
            amount + child_count * parseFloat(subPackage[0].foreign_child)
          amount =
            amount +
            child_count_range * parseFloat(subPackage[0].foreign_child_agerange)
        } else {
          amount =
            adult_count *
            subPackage[0].min_person_allowed *
            parseFloat(subPackage[0].foreign_adult)
          amount =
            amount + child_count * parseFloat(subPackage[0].foreign_child)
          amount =
            amount +
            child_count_range * parseFloat(subPackage[0].foreign_child_agerange)
        }
        //}
      }
      const data = {
        sub_pkg_id: subPackage[0]?._id,
        date: convert(date),
        user_category: user_category,
        adult_count: noofadult_count,
        child_count: noofchild_count,
        child_count_range: noofchild_count_range,
        total_amount: amount,
      }
      sessionStorage.setItem('tableData', JSON.stringify(data))
      const res = await getTicketBookingSerivce(data)

      if (res.data.status) {
        setLoading(false)
        // toast.success(res.data.message);
        // orderPayment(
        //   res.data.data.booking?.total_amount,
        //   res.data.data.booking?._id,
        //   setisPaid,
        //   setislodaing
        // );
        setbookingSummaryId(res.data.data.booking?._id)
        sessionStorage.setItem(
          'bookingSummaryId',
          JSON.stringify(res.data.data.booking._id)
        )
        navigate(`/bookingSummary`)
        getBookingSummary(res.data.data.booking?._id)
      } else {
        setLoading(false)
        toast.error(res.data.message)
        if (res.data.message === 'Invalid token') {
          userLogout()
          toast.error('Login to your account is necessary')
        }
      }
    }
  }
  const MAX_DESCRIPTION_LENGTH = 400 // You can adjust this length

  const truncateDescription = (description) => {
    return showFullDescription
      ? description
      : `${description.slice(0, MAX_DESCRIPTION_LENGTH)}...`
  }

  const [showFullDescription, setShowFullDescription] = useState(false)

  const toggleDescription = (value) => {
    setShowFullDescription(value)
  }
  const buttonStyles = {
    cursor: 'pointer',
    backgroundColor: '#007bff',
    color: '#fff',
    padding: '3px 4px',
    border: 'none',
    borderRadius: '4px',
    marginTop: '8px',
    transition: 'background-color 0.3s ease',
  }
  if (!userInfo) {
    setloginPopup(true)
  }
  return (
    <>
      {Loading ? (
        <div className='loadingMainSection'>
          <div className='loader'></div>
        </div>
      ) : islodaing ? (
        <div className='loadingMainSection'>
          <div className='loader'></div>
        </div>
      ) : (
        <div className='placeDetails'>
          <div className='innerpage-banners'>
            <img src={innerBanner} alt='inner banner' />
          </div>
          <div className='container'>
            <div className='placeDetailsinner'>
              <div className='placeDetailsinnerTxt'>
                <h1>{activeTcktId[0]?.package_name}</h1>
              </div>
              <div className='col-md-12'>
                {getactivesubpackagesdata?.filter(
                  (subpck) => subpck.pkg_id === id
                ).length > 0 ? (
                  <div className='PackageDetails'>
                    <h4>Package Details</h4>
                    <div className='pdetails-table table-responsive'>
                      <table className=' table-bordered'>
                        <thead>
                          <tr>
                            <th>Name Of Package</th>
                            <th>Duration</th>
                            <th>Persons Allowed</th>
                            <th>Package Details</th>
                            <th>Number Of Days</th>
                          </tr>
                        </thead>
                        <tbody>
                          {getactivesubpackagesdata
                            ?.filter((subpck) => subpck.pkg_id === id)
                            .map((subpackages) => (
                              <tr key={subpackages._id}>
                                {/* <th>{subpackages.pkg_name}</th> */}
                                <th>{subpackages.sub_pkg_name}</th>

                                <td>{subpackages.time}</td>
                                <td>
                                  {subpackages.min_person_allowed} -
                                  {subpackages.max_person_allowed}
                                </td>
                                <td>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: DOMPurify.sanitize(
                                        truncateDescription(
                                          subpackages.description
                                        )
                                      ),
                                    }}
                                    className={
                                      showFullDescription
                                        ? 'full-description'
                                        : 'truncated-description'
                                    }
                                  />
                                  {subpackages.description.length >
                                    MAX_DESCRIPTION_LENGTH && (
                                    <button
                                      onClick={() =>
                                        toggleDescription(!showFullDescription)
                                      }
                                      style={buttonStyles}
                                    >
                                      {showFullDescription
                                        ? 'Read Less'
                                        : 'Read More'}
                                    </button>
                                  )}
                                </td>
                                <td>
                                  {/* {subpackages.monday ? "Monday," : ""} */}
                                  {subpackages.monday
                                    ? subpackages.tuesday
                                      ? subpackages.wednesday
                                        ? subpackages.thursday
                                          ? subpackages.friday
                                            ? subpackages.saturday
                                              ? subpackages.sunday
                                                ? 'Monday,'
                                                : 'Monday'
                                              : 'Monday,'
                                            : 'Monday,'
                                          : 'Monday,'
                                        : 'Monday,'
                                      : 'Monday,'
                                    : ''}
                                  {subpackages.tuesday
                                    ? subpackages.wednesday
                                      ? subpackages.thursday
                                        ? subpackages.friday
                                          ? subpackages.saturday
                                            ? subpackages.sunday
                                              ? 'Tuesday,'
                                              : 'Tuesday'
                                            : 'Tuesday,'
                                          : 'Tuesday,'
                                        : 'Tuesday,'
                                      : 'Tuesday,'
                                    : ''}
                                  {subpackages.wednesday
                                    ? subpackages.thursday
                                      ? subpackages.friday
                                        ? subpackages.saturday
                                          ? subpackages.sunday
                                            ? 'Wednesday,'
                                            : 'Wednesday'
                                          : 'Wednesday,'
                                        : 'Wednesday,'
                                      : 'Wednesday,'
                                    : ''}
                                  <br />
                                  {subpackages.thursday
                                    ? subpackages.friday
                                      ? subpackages.saturday
                                        ? subpackages.sunday
                                          ? 'Thursday,'
                                          : 'Thursday'
                                        : 'Thursday,'
                                      : 'Thursday,'
                                    : ''}
                                  {subpackages.friday
                                    ? subpackages.saturday
                                      ? subpackages.sunday
                                        ? 'Friday,'
                                        : 'Friday'
                                      : 'Friday,'
                                    : ''}
                                  <br />
                                  {subpackages.saturday
                                    ? subpackages.sunday
                                      ? 'Saturday,'
                                      : 'Saturday'
                                    : ''}
                                  {subpackages.sunday ? 'Sunday' : ''}

                                  {/*                                       

                                    {subpackages.tuesday
                                      ? subpackages.wednesday
                                        ? "Tuesday,"
                                        : "Tuesday"
                                      : ""}
                                    {subpackages.wednesday
                                      ? subpackages.tuesday
                                        ? "Wednesday,"
                                        : "Wednesday"
                                      : ""}
                                    {subpackages.thursday
                                      ? subpackages.friday
                                        ? "Wednesday,"
                                        : "Wednesday"
                                      : ""}

                                    {subpackages.tuesday ? "Tuesday," : ""}
                                    {subpackages.wednesday ? "Wednesday," : ""}
                                    {subpackages.thursday ? "Thursday, " : ""}
                                    {subpackages.friday ? "Friday," : ""}
                                    {/* {subpackages.saturday ? "Saturday" : ""} */}
                                  {/* {subpackages.saturday
                                      ? subpackages.sunday
                                        ? "Saturday,"
                                        : "Saturday"
                                      : ""}
                                    {subpackages.sunday ? "Sunday" : ""}  */}
                                </td>
                                {/* <td>
                                  <Link to={`/booking/${subpackages._id}`}>
                                    <button className='mainButton'>
                                      Book Now
                                    </button>
                                  </Link>
                                </td> */}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className='row '>
                <div className='col-md-6'>
                  <div className='placeDetailsinnerTxtbox'>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(activeTcktId[0]?.des),
                      }}
                      className='mb-5'
                    />
                    <div className='row listdata'>
                      <div className='col-md-6'>
                        <h4>
                          Opening Time: <span>{subPackage[0]?.open_time}</span>{' '}
                        </h4>
                      </div>
                      <div className='col-md-6' style={{ marginLeft: '-12px' }}>
                        <h4>
                          Closing Time:
                          <span>{subPackage[0]?.close_time}</span>
                        </h4>
                      </div>

                      {/* <div className='col-md-6'>
                        <h4>
                          Min Person Allowed :
                          <span>{subPackage[0]?.min_person_allowed}</span>
                        </h4>
                      </div>
                      <div className='col-md-6'>
                        <h4>
                          Max Person Allowed:
                          <span>{subPackage[0]?.max_person_allowed}</span>
                        </h4>
                      </div> */}
                      {/* {activeTcktId[0]?.package_name ===
                      'Vazhvanthol Waterfalls' ? ( */}
                      <div>
                        <div className='col-md-6'>
                          <h4>
                            Extra Indian Amount :
                            <span>
                              {subPackage[0]?.extra_person_indian_amount}
                            </span>
                          </h4>
                        </div>
                        <div className='col-md-6'>
                          <h4>
                            Extra Foreign Amount :
                            <span>
                              {subPackage[0]?.extra_person_foreign_amount}
                            </span>
                          </h4>
                        </div>
                      </div>
                      {/* ) : (
                        ''
                      )} */}
                    </div>

                    <table class='table'>
                      <thead>
                        <tr>
                          <th scope='col'></th>
                          <th scope='col'>Adult</th>
                          <th scope='col'>Child (Age 0 - 5)</th>
                          <th scope='col'>Child (Age 5 - 13)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope='row'>Indian</th>
                          <td>{subPackage[0]?.indian_adult} INR</td>
                          <td>{subPackage[0]?.indian_child} INR</td>
                          <td> {subPackage[0]?.indian_child_agerange} INR</td>
                        </tr>
                        <tr>
                          <th scope='row'>Foreign</th>
                          <td>{subPackage[0]?.foreign_adult} INR</td>
                          <td>{subPackage[0]?.foreign_child} INR</td>
                          <td> {subPackage[0]?.foreign_child_agerange} INR</td>
                        </tr>
                      </tbody>
                    </table>
                    <p className='mt-5 noteText'>
                      Note: An entry fee is applicable, it will be collected in
                      the centre.
                    </p>
                  </div>
                </div>
                <div className='col-md-6'>
                  <ImageGallery items={images} lazyLoad={true} />
                </div>
                {subPackage[0]?.is_online ? (
                  <div className='col-md-12'>
                    <div>
                      <form action='' className='formStay'>
                        <h4>Ticket Booking</h4>
                        <div className='tcktUserCategory'>
                          <select
                            name='user_category'
                            value={user_category}
                            onChange={(e) => setUser_category(e.target.value)}
                            style={{
                              height: '40px',
                              width: '400px',
                              marginLeft: '14px',
                              borderRadius: '5px',
                            }}
                          >
                            <option value='Indian'>Indian</option>
                            <option value='Foreign'>Foreign</option>
                          </select>
                        </div>
                        <div className='row roomSection tckInputSection'>
                          <div className='col-md-3'>
                            <label htmlFor=''>Select a date</label>
                            <input
                              type='date'
                              placeholder='dd-mm-yyyy'
                              value={date}
                              min={getCurrentDate()}
                              onChange={(e) => setDate(e.target.value)}
                            />
                          </div>
                          <div className='col-md-3'>
                            <label htmlFor=''>Number of adult</label>
                            <input
                              type='number'
                              placeholder='Number of adult'
                              value={noofadult_count}
                              onChange={(e) =>
                                setNoOfadult_count(e.target.value)
                              }
                            />
                          </div>
                          <div className='col-md-3'>
                            <label htmlFor=''>
                              {' '}
                              Number of Child (Age: 0 - 5)
                            </label>
                            <input
                              type='number'
                              placeholder='Number of Child'
                              value={noofchild_count}
                              onChange={(e) =>
                                setNoOfchild_count(e.target.value)
                              }
                            />
                          </div>
                          <div className='col-md-3'>
                            <label htmlFor=''>
                              Number of Child (Age: 5 - 13)
                            </label>
                            <input
                              type='number'
                              placeholder='Number of Child'
                              value={noofchild_count_range}
                              onChange={(e) =>
                                setNoOfchild_count_range(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </form>
                      <div className='submitBtn btntckConatiner'>
                        {isPaid ? (
                          <>
                            <button
                              className='mainButton'
                              onClick={handlePrint}
                            >
                              Print
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              className='mainButton'
                              onClick={(e) => submitHandlers(e)}
                            >
                              Pay Now
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                    <div className='PackageFacility'>
                      <h5>Facility</h5>
                      <div className='row'>
                        {facilities?.map((Facility, index) => (
                          <div className='col-md-3' key={index}>
                            <div className='FacilityItems'>
                              <span>{Facility}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                      <h5>Activities</h5>
                      <div className='row'>
                        {activities?.map((activities, index) => (
                          <div className='col-md-3' key={index}>
                            <div className='FacilityItems'>
                              <span>{activities}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div style={{ display: 'none' }}>
                        <div ref={componentRef} style={{ width: '100%' }}>
                          <PrintScreen
                            bookingSummaryinfo={bookingSummaryinfo}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <h2>Ticket Booking</h2>
                    <div style={warningStyle}>Offline Booking Only</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
