import React, { useState } from "react";
import ManinBanner from "./ManinBanner";
import "./home.css";
import FilterGallery from "./FilterGallery";
import { useEffect } from "react";
import Loading from "../../Layouts/Loading";
import { scrollTobottom } from "../../../Services/CommonService";
import Home2 from "../../../Assets/Images/home2.png";
import Event from "../Event/Event";

function Home({
  getactivestaydata,
  getactivecategoriesdata,
  getactivePackagesdata,
  getactivelocationsdata,
  getactiveticketdata,
  getactiveEventdata,
}) {
  const [catId, setcatId] = useState("");
  const getcatID = (index) => {
    if (!index) {
      setcatId(getactivecategoriesdata[0]?._id);
    } else {
      setcatId(getactivecategoriesdata[index]?._id);
    }
  };

  useEffect(() => {
    getcatID();
  }, [getactivecategoriesdata]);

  const getCatID = (index) => {
    getcatID(index);
    scrollTobottom();
  };

  useEffect(() => {
    sessionStorage.removeItem("tableData");
    sessionStorage.removeItem("dateDeatils");
    sessionStorage.removeItem("bookingSummaryId");
  }, [])
  

  return (
    <>
      {getactivecategoriesdata?.length > 0 ? (
        <div className="homeWapper">
          <ManinBanner
            getCatID={getCatID}
            getactivecategoriesdata={getactivecategoriesdata}
            catId={catId}
          />
          <FilterGallery
            getactivestaydata={getactivestaydata}
            getactiveticketdata={getactiveticketdata}
            catId={catId}
            getactivelocations={getactivelocationsdata}
            getactivePackagesdata={getactivePackagesdata}
          />
          <div className="demoBanner">
            <div className="imageConatinerBanner">
              <img src={Home2} alt="" />
              <h1>
                “Look deep into nature, and <br /> then you will understand{" "}
                <br /> everything better”
              </h1>
            </div>
          </div>
          <div className="eventData">
            <Event getactiveEventdata={getactiveEventdata} />
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default Home;
