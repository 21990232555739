import React, { useEffect, useState } from 'react'
import Logo from '../../Assets/Images/logo.svg'
import mLogo from '../../Assets/Images/logoMobile.png'
import Avatar from '../../Assets/Images/profile.png'
import './Layouts.css'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  changePassword,
  forgotPassword,
  forgotPasswordOTPHandler,
  getuserdetails,
  loginHandler,
  OTPHandler,
  submitHandler,
  userLogout,
} from './UserService'
import { toast } from 'react-toastify'
import { scrollToTop } from '../../Services/CommonService'

function Header({ loginPopup, setloginPopup, dropdownMenu, setdropdownMenu }) {
  const [email, setemail] = useState('')
  const [phone, setphone] = useState('')
  const [userDetail, setuserDetail] = useState('')
  const [name, setname] = useState('')
  const [password, setpassword] = useState('')
  const [cnpassword, setcnpassword] = useState('')
  const [menuSection, setmenuSection] = useState('home')
  const [isforgotPassword, setisforgotPassword] = useState(false)
  const [isforgotPasswordOtp, setisforgotPasswordOtp] = useState(false)
  const [OTP, setOTP] = useState(false)
  const [signupPopup, setsignupPopup] = useState(false)
  const [otpdata, setotpdata] = useState('')
  const [isLoading, setisLoading] = useState(false)
  const [hasBackground, setHasBackground] = useState(false)
  const [showConfirmPasswod, setIsShowConfirmPassowrd] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [oldPassword, setOldPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const fromData = {
    email: email,
    phone: phone,
    role: 'user',
    name: name,
    password: password,
  }
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const userInfo = JSON.parse(localStorage.getItem('user'))

  // Function to handle scrolling and change the background color
  const handleScroll = () => {
    if (window.scrollY > 0) {
      // Add the redBackground class when scrolled
      setHasBackground(true)
    } else {
      // Remove the redBackground class when not scrolled
      setHasBackground(false)
    }
  }
  const haveanAccount = () => {
    setloginPopup(true)
    setsignupPopup(false)
  }
  const asNewUser = () => {
    setloginPopup(false)
    setsignupPopup(true)
    setemail('')
    setpassword('')
  }
  const loginClose = () => {
    setloginPopup(false)
    setisforgotPassword(false)
    setemail('')
    setpassword('')
    navigate('/')
  }

  const newuserReg = () => {
    setsignupPopup(true)
    setOTP(false)
    setisforgotPassword(false)
    setemail('')
    setpassword('')
  }
  const userLogin = () => {
    setloginPopup(true)
    // setOTP(false);
    setisforgotPassword(false)
    setemail('')
    setpassword('')
  }

  useEffect(() => {
    if (userInfo) {
      getuserdetails(setuserDetail)
    }
    // setemail("");
    // setpassword("");
    // setOTP(false);
    setisforgotPassword(false)
  }, [loginPopup])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    // setsignupPopup(false);
    // setloginPopup(false);
  }, [OTP])
  useEffect(() => {
    if (pathname.includes('paymentGateway')) {
      setHasBackground(true)
    } else {
      setHasBackground(false)
    }
  }, [pathname])

  const menuClick = (menu) => {
    setmenuSection(menu)
    scrollToTop()
  }
  const forgetpasswordotpSubmit = (e) => {
    e.preventDefault()
    if (password.trim() === '') {
      toast.error('Please enter password')
    } else if (cnpassword.trim() === '') {
      toast.error('Please enter confirm password')
    } else if (password.trim() !== cnpassword.trim()) {
      toast.error('Password does not match')
    } else {
      forgotPasswordOTPHandler(
        e,
        email,
        password,
        otpdata,
        setisforgotPasswordOtp,
        setisforgotPassword,
        setisLoading
      )
    }
  }

  const handleChangePasswordSubmit = (e) => {
    e.preventDefault()
    if (oldPassword.trim() === '') {
      toast.error('Please Enter Your Current Password')
    } else if (newPassword.trim() === '') {
      toast.error('Please enter the new password')
    } else if (confirmNewPassword.trim() === '') {
      toast.error('Please enter confirm the new password')
    } else if (newPassword.trim() !== confirmNewPassword.trim()) {
      toast.error('New password does not match the confirmation')
    } else {
      changePassword(
        e,
        email,
        newPassword,
        oldPassword,
        setisLoading,
        setIsShowConfirmPassowrd
      )
    }
  }

  const openChangePasswordModal = () => {
    setIsShowConfirmPassowrd(true)
    setNewPassword('')
    setConfirmNewPassword('')
    setOldPassword('')
    setloginPopup(false)
  }
  return (
    <div className={`mainHeader ${hasBackground && 'scrollBackground'}`}>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-md-3'>
            <Link to='/' onClick={() => menuClick('home')}>
              <div className='logoWapper'>
                <img src={Logo} alt='logo' className='webLogo' />
                <img src={mLogo} alt='logo' className='mobLogo' />
              </div>
            </Link>
          </div>
          <div className='col-md-8'>
            <nav className='maninNav'>
              <ul>
                <li>
                  <Link
                    to='/'
                    onClick={() => menuClick('home')}
                    className={`${menuSection === 'home' ? `activeMenu` : ``}`}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to='/PlaceListing'
                    onClick={() => menuClick('PlaceListing')}
                    className={`${
                      menuSection === 'PlaceListing' ? `activeMenu` : ``
                    }`}
                  >
                    Plan Your Visit
                  </Link>
                </li>
                <li>
                  <Link
                    to='/About'
                    onClick={() => menuClick('About')}
                    className={`${menuSection === 'About' ? `activeMenu` : ``}`}
                  >
                    About Reserve
                  </Link>
                </li>
                <li>
                  <Link
                    to='/eventlisting'
                    onClick={() => menuClick('eventlisting')}
                    className={`${
                      window.location.href.includes('eventlisting')
                        ? `activeMenu`
                        : ``
                    }`}
                  >
                    What's New
                  </Link>
                </li>
                <li>
                  <Link
                    to='/Contact'
                    onClick={() => menuClick('Contact')}
                    className={`${
                      window.location.href.includes('Contact')
                        ? `activeMenu`
                        : ``
                    }`}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className='col-md-1'>
            <div className='navRight'>
              {/* <form>
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                  />
                  <i className="fa fa-search"></i>
                </div>
              </form> */}
              <div className='rightBtn'>
                <div className='dropdown'>
                  <button
                    className='btn dropdown-toggle'
                    type='button'
                    id='dropdownMenuButton1'
                    onClick={() => setdropdownMenu(!dropdownMenu)}
                  >
                    <img src={Avatar} alt={Avatar} />
                    {userDetail ? (
                      <span>{userDetail?.name}</span>
                    ) : (
                      <span>{userInfo?.name}</span>
                    )}
                  </button>
                  <ul
                    className='dropdown-menu'
                    aria-labelledby='dropdownMenuButton1'
                    style={{ display: `${!dropdownMenu ? `none` : `block`}` }}
                  >
                    {userInfo?.session ? (
                      <>
                        <li>
                          <Link
                            to='/myBookings'
                            onClick={() => menuClick('myBookings')}
                          >
                            <span className='dropdown-item'> My Bookings</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/profile'
                            onClick={() => menuClick('profile')}
                          >
                            <span className='dropdown-item'> My Profile</span>
                          </Link>
                        </li>
                        <li>
                          <Link to='' onClick={() => openChangePasswordModal()}>
                            <span className='dropdown-item'>
                              Change Password
                            </span>
                          </Link>
                        </li>
                      </>
                    ) : (
                      ''
                    )}

                    <div _ngcontent-fru-c135=''></div>
                    {userInfo?.session ? (
                      ''
                    ) : (
                      <>
                        <li>
                          <button
                            className='dropdown-item btn'
                            onClick={() => userLogin()}
                          >
                            Login
                          </button>
                        </li>
                        <li>
                          <button
                            className='dropdown-item btn'
                            onClick={() => newuserReg()}
                          >
                            New User
                          </button>
                        </li>
                      </>
                    )}

                    {userInfo?.session ? (
                      <li>
                        <button
                          className='dropdown-item btn'
                          onClick={() => userLogout()}
                        >
                          Logout
                        </button>
                      </li>
                    ) : (
                      ''
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className='modal loginModal '
        id='myModal'
        style={{ display: `${loginPopup ? `block` : `none`}` }}
      >
        <div className='modal-dialog modal-md'>
          <div className='modal-content'>
            <div className='modal-header'>
              {isforgotPassword ? (
                <h4 className='modal-title'>Forgot Password</h4>
              ) : (
                <h4 className='modal-title'>Login</h4>
              )}

              <button
                type='button'
                className='btn-close'
                onClick={() => loginClose()}
              ></button>
            </div>

            <div className='modal-body'>
              {isLoading ? (
                <div className='loadingMainSection' style={{ height: '200px' }}>
                  <div className='loader'></div>
                </div>
              ) : (
                <>
                  <form>
                    <div className='formArea'>
                      <label htmlFor='email' className='form-label'>
                        Email:
                      </label>
                      <input
                        type='email'
                        className='form-control'
                        placeholder='Enter email'
                        name='email'
                        autoComplete='on'
                        value={email}
                        onChange={(e) => setemail(e.target.value)}
                      />
                    </div>
                    {isforgotPassword ? (
                      <button
                        type='submit'
                        className='btn btn-submit'
                        onClick={(e) =>
                          forgotPassword(
                            e,
                            email,
                            setisforgotPasswordOtp,
                            setloginPopup,
                            setOTP,
                            setisLoading
                          )
                        }
                      >
                        Submit
                      </button>
                    ) : (
                      <>
                        <div className='formArea'>
                          <label htmlFor='password' className='form-label'>
                            Password:
                          </label>
                          <input
                            type='password'
                            className='form-control'
                            placeholder='Enter Password'
                            name='password'
                            autoComplete='on'
                            value={password}
                            onChange={(e) => setpassword(e.target.value)}
                          />
                        </div>
                        <div className='formArea forgotPasswordSection'>
                          <p onClick={(e) => setisforgotPassword(true)}>
                            Forgot Password ?
                          </p>
                        </div>
                        <button
                          type='submit'
                          className='btn btn-submit'
                          onClick={(e) =>
                            loginHandler(
                              e,
                              email,
                              password,
                              fromData,
                              setloginPopup,
                              setisLoading
                            )
                          }
                        >
                          Submit
                        </button>
                      </>
                    )}
                  </form>
                  {isforgotPassword ? (
                    ' '
                  ) : (
                    <button
                      type='submit'
                      className='btn newUser'
                      onClick={() => asNewUser()}
                    >
                      Sign up as new user
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className='modal loginModal'
        id='newUser'
        style={{ display: `${signupPopup ? `block` : `none`}` }}
      >
        <div className='modal-dialog modal-md'>
          {OTP ? (
            <div className='modal-content'>
              <div className='modal-header'>
                <h4 className='modal-title'> Verify OTP</h4>
                <button
                  type='button'
                  className='btn-close'
                  onClick={() => setsignupPopup(false)}
                ></button>
              </div>

              <div className='modal-body'>
                {isLoading ? (
                  ''
                ) : (
                  <form>
                    <div className='formArea'>
                      <label htmlFor='email' className='form-label'>
                        OTP:
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Enter otp'
                        name='otp'
                        value={otpdata}
                        onChange={(e) => setotpdata(e.target.value)}
                      />
                    </div>

                    <button
                      type='submit'
                      className='btn btn-submit'
                      onClick={(e) =>
                        OTPHandler(
                          e,
                          email,
                          otpdata,
                          setOTP,
                          setsignupPopup,
                          setisLoading
                        )
                      }
                    >
                      Submit
                    </button>
                  </form>
                )}
              </div>
            </div>
          ) : (
            <div className='modal-content'>
              <div className='modal-header'>
                <h4 className='modal-title'>Sign up as new user</h4>
                <button
                  type='button'
                  className='btn-close'
                  onClick={() => setsignupPopup()}
                ></button>
              </div>

              <div className='modal-body'>
                {isLoading ? (
                  <div
                    className='loadingMainSection'
                    style={{ height: '200px' }}
                  >
                    <div className='loader'></div>
                  </div>
                ) : (
                  <>
                    <form>
                      <div className='formArea'>
                        <label htmlFor='Name' className='form-label'>
                          Name:
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Name'
                          name='Name'
                          value={name}
                          onChange={(e) => setname(e.target.value)}
                        />
                      </div>
                      <div className='formArea'>
                        <label htmlFor='mobile' className='form-label'>
                          Mobile Number:
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Mobile'
                          name='mobile'
                          value={phone}
                          onChange={(e) => setphone(e.target.value)}
                        />
                      </div>
                      <div className='formArea'>
                        <label htmlFor='email' className='form-label'>
                          Email:
                        </label>
                        <input
                          type='email'
                          className='form-control'
                          placeholder='Enter email'
                          name='email'
                          value={email}
                          onChange={(e) => setemail(e.target.value)}
                        />
                      </div>
                      <div className='formArea'>
                        <label htmlFor='password' className='form-label'>
                          Password:
                        </label>
                        <input
                          type='password'
                          className='form-control'
                          placeholder='Enter password'
                          name='password'
                          autoComplete='on'
                          value={password}
                          onChange={(e) => setpassword(e.target.value)}
                        />
                      </div>
                      <div className='formArea'>
                        <label htmlFor='password' className='form-label'>
                          Confirm Password
                        </label>
                        <input
                          type='password'
                          className='form-control'
                          placeholder='Enter password'
                          name='password'
                          autoComplete='on'
                          value={cnpassword}
                          onChange={(e) => setcnpassword(e.target.value)}
                        />
                      </div>
                      <button
                        type='submit'
                        className='btn btn-submit'
                        onClick={(e) =>
                          submitHandler(
                            e,
                            email,
                            password,
                            cnpassword,
                            fromData,
                            setOTP,
                            name,
                            phone,
                            setisLoading
                          )
                        }
                      >
                        Submit
                      </button>
                    </form>
                    <button
                      type='submit'
                      className='btn newUser'
                      onClick={() => haveanAccount()}
                    >
                      Already have an account ?
                    </button>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className='modal loginModal'
        id='newUser'
        style={{ display: `${isforgotPasswordOtp ? `block` : `none`}` }}
      >
        <div className='modal-dialog modal-md'>
          {OTP ? (
            <div className='modal-content'>
              <div className='modal-header'>
                <h4 className='modal-title'> Verify OTP</h4>
                <button
                  type='button'
                  className='btn-close'
                  onClick={() => setisforgotPasswordOtp(false)}
                ></button>
              </div>

              <div className='modal-body'>
                {isLoading ? (
                  <div
                    className='loadingMainSection'
                    style={{ height: '200px' }}
                  >
                    <div className='loader'></div>
                  </div>
                ) : (
                  <form>
                    <div className='formArea'>
                      <label htmlFor='password' className='form-label'>
                        New Password:
                      </label>
                      <input
                        type='password'
                        className='form-control'
                        placeholder='Enter Password'
                        onChange={(e) => setpassword(e.target.value)}
                      />
                    </div>
                    <div className='formArea'>
                      <label htmlFor='password' className='form-label'>
                        Confirm Password:
                      </label>
                      <input
                        type='password'
                        className='form-control'
                        placeholder='Enter Password'
                        name='password'
                        autoComplete='on'
                        value={cnpassword}
                        onChange={(e) => setcnpassword(e.target.value)}
                      />
                    </div>
                    <div className='formArea'>
                      <label htmlFor='email' className='form-label'>
                        OTP:
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Enter otp'
                        name='otp'
                        value={otpdata}
                        onChange={(e) => setotpdata(e.target.value)}
                      />
                    </div>

                    <button
                      type='submit'
                      className='btn btn-submit'
                      onClick={(e) => forgetpasswordotpSubmit(e)}
                    >
                      Submit
                    </button>
                  </form>
                )}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div
        className='modal loginModal'
        id='changePasswordModal'
        style={{ display: `${showConfirmPasswod ? `block` : `none`}` }}
      >
        <div className='modal-dialog modal-md'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h4 className='modal-title'>Change Password</h4>
              <button
                type='button'
                className='btn-close'
                onClick={() => setIsShowConfirmPassowrd(false)}
              ></button>
            </div>

            <div className='modal-body'>
              {isLoading ? (
                <div className='loadingMainSection' style={{ height: '200px' }}>
                  <div className='loader'></div>
                </div>
              ) : (
                <form>
                  <div className='formArea'>
                    <label htmlFor='OldPassword' className='form-label'>
                      Old Password:
                    </label>
                    <input
                      type='password'
                      className='form-control'
                      placeholder='Enter old password'
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                    />
                  </div>
                  <div className='formArea'>
                    <label htmlFor='newPassword' className='form-label'>
                      New Password:
                    </label>
                    <input
                      type='password'
                      className='form-control'
                      placeholder='Enter new password'
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </div>
                  <div className='formArea'>
                    <label htmlFor='confirmNewPassword' className='form-label'>
                      Confirm New Password:
                    </label>
                    <input
                      type='password'
                      className='form-control'
                      placeholder='Confirm new password'
                      value={confirmNewPassword}
                      onChange={(e) => setConfirmNewPassword(e.target.value)}
                    />
                  </div>
                  <button
                    type='submit'
                    className='btn btn-submit'
                    onClick={(e) => handleChangePasswordSubmit(e)}
                  >
                    Submit
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
