import React, { useEffect, useState } from 'react'
import './placeDetails.css'
import { Link, useParams } from 'react-router-dom'
import innerBanner from '../../../Assets/Images/inner-banner/inner-banner.jpg'
import { BaseUrl } from '../../../Services/baseUrl'

import createDOMPurify from 'dompurify'
import ImageGallery from 'react-image-gallery'
import Loading from '../../Layouts/Loading'
import { scrollToTop } from '../../../Services/CommonService'
const warningStyle = {
  padding: '10px',
  color: '#ff0000',
  // fontWeight: 'bold',
}
function PlaceDetails({
  getactivestaydata,
  getactivePackagesdata,
  getactivesubpackagesdata,
}) {
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false)
  const [showFullDescription, setShowFullDescription] = useState(false)
  const maxDescriptionLength = 1600
  const maxtableDescriptionLength = 300
  const params = useParams()
  const { id } = params

  const DOMPurify = createDOMPurify(window)

  const activePackagesId = getactivePackagesdata?.filter(
    (pckge) => pckge._id === id
  )
  const activeStayId = getactivestaydata?.filter((pckge) => pckge._id === id)
  var facilities = getactivePackagesdata[0]?.facilities?.split(',')
  var activities = getactivePackagesdata[0]?.activities?.split(',')

  const images = [
    {
      original: `${BaseUrl}/${activePackagesId[0]?.image1}`,
      thumbnail: `${BaseUrl}/${activePackagesId[0]?.image1}`,
    },
    {
      original: `${BaseUrl}/${activePackagesId[0]?.image2}`,
      thumbnail: `${BaseUrl}/${activePackagesId[0]?.image2}`,
    },
    {
      original: `${BaseUrl}/${activePackagesId[0]?.image3}`,
      thumbnail: `${BaseUrl}/${activePackagesId[0]?.image3}`,
    },
    {
      original: `${BaseUrl}/${activePackagesId[0]?.image4}`,
      thumbnail: `${BaseUrl}/${activePackagesId[0]?.image4}`,
    },
    {
      original: `${BaseUrl}/${activePackagesId[0]?.image5}`,
      thumbnail: `${BaseUrl}/${activePackagesId[0]?.image5}`,
    },
  ]
  useEffect(() => {
    scrollToTop()
  }, [])
  const truncateDescription = (description) => {
    return showFullDescription
      ? description
      : `${description.slice(0, maxtableDescriptionLength)}...`
  }
  const toggleDescription = (value) => {
    setShowFullDescription(value)
  }
  const buttonStyles = {
    cursor: 'pointer',
    backgroundColor: '#007bff',
    color: '#fff',
    padding: '3px 4px',
    border: 'none',
    borderRadius: '4px',
    marginTop: '8px',
    transition: 'background-color 0.3s ease',
  }

  return (
    <>
      {getactivePackagesdata.length > 0 &&
      getactivesubpackagesdata.length > 0 ? (
        <div className='placeDetails'>
          <div className='innerpage-banners'>
            <img src={innerBanner} alt='inner banner' />
          </div>
          <div className='container'>
            <div className='placeDetailsinner'>
              <div className='placeDetailsinnerTxt'>
                <h1>{activePackagesId[0]?.package_name}</h1>
              </div>
              <div className='row '>
                <div className='col-md-6'>
                  <div className='placeDetailsinnerTxtbox'>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          isDescriptionExpanded
                            ? activePackagesId[0]?.des
                            : activePackagesId[0]?.des.slice(
                                0,
                                maxDescriptionLength
                              ) + '...'
                        ),
                      }}
                    />
                    {!isDescriptionExpanded &&
                    activePackagesId[0]?.des.length > maxDescriptionLength ? (
                      <button
                        onClick={() => setIsDescriptionExpanded(true)}
                        style={{
                          padding: '8px 12px',
                          backgroundColor: '#007BFF',
                          color: 'white',
                          cursor: 'pointer',
                          border: 'none',
                          borderRadius: '4px',
                        }}
                      >
                        See More
                      </button>
                    ) : (
                      ''
                    )}

                    {isDescriptionExpanded && (
                      <button
                        onClick={() => setIsDescriptionExpanded(false)}
                        style={{
                          padding: '8px 12px',
                          backgroundColor: '#007BFF',
                          color: 'white',
                          cursor: 'pointer',
                          border: 'none',
                          borderRadius: '4px',
                        }}
                      >
                        See less
                      </button>
                    )}
                  </div>
                </div>
                <div className='col-md-6'>
                  <ImageGallery items={images} lazyLoad={true} />
                </div>
                <div className='col-md-12'>
                  {getactivesubpackagesdata?.filter(
                    (subpck) => subpck.pkg_id === id
                  ).length > 0 ? (
                    <div className='PackageDetails'>
                      <h4>Package Details</h4>
                      <div className='pdetails-table table-responsive'>
                        <table className=' table-bordered'>
                          <thead>
                            <tr>
                              <th>Name Of Package</th>
                              <th>Indian Adult (Rs.)</th>
                              <th>Indian Child (Rs.)</th>
                              <th>Foreign Adult (Rs.)</th>
                              <th>Foreign Child (Rs.)</th>
                              <th>Duration</th>
                              <th>Persons Allowed</th>
                              <th>Package Details</th>
                              <th>Days of Trekking</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {getactivesubpackagesdata
                              ?.filter((subpck) => subpck.pkg_id === id)
                              .map((subpackages) => (
                                <tr key={subpackages._id}>
                                  {/* <th>{subpackages.pkg_name}</th> */}
                                  <th>{subpackages.sub_pkg_name}</th>
                                  <td>
                                    {subpackages.indian_adult === '0'
                                      ? 'Not Allowed'
                                      : subpackages.indian_adult + '.00'}
                                  </td>
                                  <td>
                                    {subpackages.indian_child === '0'
                                      ? 'Not Allowed'
                                      : subpackages.indian_child + '.00'}
                                  </td>
                                  <td>
                                    {subpackages.foreign_adult === '0'
                                      ? 'Not Allowed'
                                      : subpackages.foreign_adult + '.00'}
                                  </td>
                                  <td>
                                    {subpackages.foreign_child === '0'
                                      ? 'Not Allowed'
                                      : subpackages.foreign_child + '.00'}
                                  </td>
                                  <td>{subpackages.time}</td>
                                  <td>
                                    {subpackages.min_person_allowed} -
                                    {subpackages.max_person_allowed}
                                  </td>
                                  <td>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                          truncateDescription(
                                            subpackages.description
                                          )
                                        ),
                                      }}
                                      className={
                                        showFullDescription
                                          ? 'full-description'
                                          : 'truncated-description'
                                      }
                                    />{' '}
                                    {subpackages.description.length >
                                      maxtableDescriptionLength && (
                                      <button
                                        onClick={() =>
                                          toggleDescription(
                                            !showFullDescription
                                          )
                                        }
                                        style={buttonStyles}
                                      >
                                        {showFullDescription
                                          ? 'Read Less'
                                          : 'Read More'}
                                      </button>
                                    )}
                                  </td>

                                  <td>
                                    {/* {subpackages.monday ? "Monday," : ""} */}
                                    {subpackages.monday
                                      ? subpackages.tuesday
                                        ? subpackages.wednesday
                                          ? subpackages.thursday
                                            ? subpackages.friday
                                              ? subpackages.saturday
                                                ? subpackages.sunday
                                                  ? 'Monday,'
                                                  : 'Monday'
                                                : 'Monday,'
                                              : 'Monday,'
                                            : 'Monday,'
                                          : 'Monday,'
                                        : 'Monday,'
                                      : ''}
                                    {subpackages.tuesday
                                      ? subpackages.wednesday
                                        ? subpackages.thursday
                                          ? subpackages.friday
                                            ? subpackages.saturday
                                              ? subpackages.sunday
                                                ? 'Tuesday,'
                                                : 'Tuesday'
                                              : 'Tuesday,'
                                            : 'Tuesday,'
                                          : 'Tuesday,'
                                        : 'Tuesday,'
                                      : ''}
                                    {subpackages.wednesday
                                      ? subpackages.thursday
                                        ? subpackages.friday
                                          ? subpackages.saturday
                                            ? subpackages.sunday
                                              ? 'Wednesday,'
                                              : 'Wednesday'
                                            : 'Wednesday,'
                                          : 'Wednesday,'
                                        : 'Wednesday,'
                                      : ''}
                                    <br />
                                    {subpackages.thursday
                                      ? subpackages.friday
                                        ? subpackages.saturday
                                          ? subpackages.sunday
                                            ? 'Thursday,'
                                            : 'Thursday'
                                          : 'Thursday,'
                                        : 'Thursday,'
                                      : ''}
                                    {subpackages.friday
                                      ? subpackages.saturday
                                        ? subpackages.sunday
                                          ? 'Friday,'
                                          : 'Friday'
                                        : 'Friday,'
                                      : ''}
                                    <br />
                                    {subpackages.saturday
                                      ? subpackages.sunday
                                        ? 'Saturday,'
                                        : 'Saturday'
                                      : ''}
                                    {subpackages.sunday ? 'Sunday' : ''}

                                    {/*                                       

                                    {subpackages.tuesday
                                      ? subpackages.wednesday
                                        ? "Tuesday,"
                                        : "Tuesday"
                                      : ""}
                                    {subpackages.wednesday
                                      ? subpackages.tuesday
                                        ? "Wednesday,"
                                        : "Wednesday"
                                      : ""}
                                    {subpackages.thursday
                                      ? subpackages.friday
                                        ? "Wednesday,"
                                        : "Wednesday"
                                      : ""}

                                    {subpackages.tuesday ? "Tuesday," : ""}
                                    {subpackages.wednesday ? "Wednesday," : ""}
                                    {subpackages.thursday ? "Thursday, " : ""}
                                    {subpackages.friday ? "Friday," : ""}
                                    {/* {subpackages.saturday ? "Saturday" : ""} */}
                                    {/* {subpackages.saturday
                                      ? subpackages.sunday
                                        ? "Saturday,"
                                        : "Saturday"
                                      : ""}
                                    {subpackages.sunday ? "Sunday" : ""}  */}
                                  </td>
                                  <td>
                                    {subpackages?.is_online ? (
                                      <Link to={`/booking/${subpackages._id}`}>
                                        <button className='mainButton'>
                                          Book Now
                                        </button>
                                      </Link>
                                    ) : (
                                      <div style={warningStyle}>
                                        <p>Offline only</p>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                  <div className='PackageFacility'>
                    <h5>Facility</h5>
                    <div className='row'>
                      {facilities?.map((Facility, index) => (
                        <div className='col-md-3' key={index}>
                          <div className='FacilityItems'>
                            <span>{Facility}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                    <h5>Activities</h5>
                    <div className='row'>
                      {activities?.map((activities, index) => (
                        <div className='col-md-3' key={index}>
                          <div className='FacilityItems'>
                            <span>{activities}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  )
}

export default PlaceDetails
