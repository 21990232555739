export const namevalidation = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g
export const PhoneNumber = /^[0-9]{10}$/g
export const scrollTobottom = (index) => {
  window.scrollTo(0, 500)
}
export const scrollToEnd = (index) => {
  // window.scrollBy(0, 500);
}
export const dateconvert = (str) => {
  var date = new Date(str),
    mnth = ('0' + (date.getMonth() + 1)).slice(-2),
    day = ('0' + date.getDate()).slice(-2)
  return [day, mnth, date.getFullYear()].join('-')
}

export const scrollToTop = () => {
  window.scroll({
    top: 0,
    left: 100,
    behavior: 'smooth',
  })
}

export const getCurrentDate = () => {
  const today = new Date()
  const year = today.getFullYear()
  const month = String(today.getMonth() + 1).padStart(2, '0') // Add 1 because months are zero-based
  const day = String(today.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

export const formatDateToDDMMYYYY = () => {
  const currentDate = new Date()
  const day = String(currentDate.getDate()).padStart(2, '0') // Get day and pad with leading zero if necessary
  const month = String(currentDate.getMonth() + 1).padStart(2, '0') // Get month (0-based) and pad with leading zero
  const year = currentDate.getFullYear()
  return `${day}-${month}-${year}`
}
export const convert = (str) => {
  var date = new Date(str),
    mnth = ('0' + (date.getMonth() + 1)).slice(-2),
    day = ('0' + date.getDate()).slice(-2)
  return [day, mnth, date.getFullYear()].join('-')
}

export const convertDateToFormattedString = (inputDate) => {
  // Create a new Date object by subtracting 3 months and 3 days from the input date
  const convertedDate = new Date(inputDate)
  convertedDate.setMonth(inputDate.getMonth() - 3)
  convertedDate.setDate(inputDate.getDate() - 3)

  // Format the converted date as 'yyyy-mm-dd'
  const year = convertedDate.getFullYear()
  const month = (convertedDate.getMonth() + 1).toString().padStart(2, '0')
  const day = convertedDate.getDate().toString().padStart(2, '0')
  const formattedDate = `${year}-${month}-${day}`

  return formattedDate
}
