import React from 'react'
import Loading from '../../Layouts/Loading'
import innerBanner from '../../../Assets/Images/inner-banner/inner-banner1.jpg'
import { Link } from 'react-router-dom'
import createDOMPurify from 'dompurify'
import { BaseUrl } from '../../../Services/baseUrl'
export default function EventDetails({ getactiveEventdata }) {
  const DOMPurify = createDOMPurify(window)
  return (
    <>
      {getactiveEventdata.length > 0 ? (
        <div className='filterGallery PlaceListing'>
          <div className='innerpage-banners'>
            <img src={innerBanner} alt='inner banner' />
            <h3 className='innerBanerTitle'> What's New</h3>
          </div>
          <div className='container'>
            <div className='tab-content'>
              <div id='home' className='tab-pane active'>
                <div className='row'>
                  {getactiveEventdata?.map((event) => (
                    <div className='col-md-4' key={event._id}>
                      <div className='PlaceListingCard'>
                        <div className='PlaceListingCardImg'>
                          <img src={`${BaseUrl}/${event.image1}`} alt='' />
                        </div>
                        <div className='PlaceListingCardTxt'>
                          <div className='PlaceListingCardTxtBox'>
                            <h5>{event.title}</h5>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  event.description.length > 150
                                    ? event.description.substring(0, 150) +
                                        '...'
                                    : event.description
                                ),
                              }}
                            />

                            <Link to={`/eventdetails/${event._id}`}>
                              <button className='mainButton'>
                                View Details
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  )
}
