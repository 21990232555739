import React, { useEffect, useRef, useState } from 'react'
import { BaseUrl } from '../../../Services/baseUrl'
import { Calendar } from 'primereact/calendar'
import './bookings.scss'
import QRCode from 'react-qr-code'
import Loading from '../../Layouts/Loading'
import { getBookingHistoryCall } from '../../../Services/BaseApiService'
import { toast } from 'react-toastify'
import {
  getBookingSummarySerivce,
  trekkingavailableslotsSerivce,
} from '../../../Services/BaseApiPostService'
import { userLogout } from '../../Layouts/UserService'
import { useReactToPrint } from 'react-to-print'
import { PrintScreen } from '../printScreen/PrintScreen'
import innerBanner from '../../../Assets/Images/inner-banner/inner-banner.jpg'
import { stayavailableslotsSerivce } from '../../../Services/BaseApiPostService'
import { useNavigate } from 'react-router-dom'
import { OverlayPanel } from 'primereact/overlaypanel'
import { convert, formatDateToDDMMYYYY } from '../../../Services/CommonService'

export const BookingHistory = ({ setloginPopup }) => {
  const [bookingHis, setbookingHis] = useState([])
  const [isLoading, setislodaing] = useState(false)
  const [bookingSummaryinfo, setbookingSummaryinfo] = useState({})
  const [isbookingSummary, setisbookingSummary] = useState(false)
  const [bookingSummaryId, setbookingSummaryId] = useState('')
  const [bookingViewid, setbookingViewid] = useState('')
  const [isPaid, setisPaid] = useState(false)
  const [stayavailableslotsdata, setstayavailableslots] = useState([])
  const [dates, setDates] = useState([])
  const [startDate, setstartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [selectedId, setSelectedId] = useState('')
  const [selectedBookingId, setSelectedBookingId] = useState('')

  const userInfo = JSON.parse(localStorage.getItem('user'))
  const componentRef = useRef()
  const popupcalander = useRef(null)
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    sessionStorage.removeItem('tableData')
    sessionStorage.removeItem('dateDeatils')
    sessionStorage.removeItem('bookingSummaryId')
    scrollToTop() // Scroll to top when the component mounts
  }, [])
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'TWD',
  })
  const printHandler = async (id) => {
    const dataForm = {
      id: id,
    }
    const res = await getBookingSummarySerivce(dataForm)
    if (res.data.status) {
      setbookingSummaryinfo(res.data.data)
      setTimeout(() => {
        handlePrint()
      }, 500)
    }
  }
  let navigate = useNavigate()

  const getBookingHistoryHandler = async () => {
    setislodaing(true)

    const res = await getBookingHistoryCall()
    if (res.data.status) {
      setbookingHis(res.data.data)
      setislodaing(false)
    } else {
      toast.error('Session expired')
      if (res.data.message === 'Invalid token') {
        userLogout()
      }
    }
  }

  const getbookingsHandler = async (id) => {
    setbookingViewid(id)
    setislodaing(true)
    setbookingSummaryId(id)
    const dataForm = {
      id: id,
    }
    const res = await getBookingSummarySerivce(dataForm)

    if (res.data.status) {
      setbookingSummaryinfo(res.data.data)
      setisbookingSummary(true)
      setislodaing(false)
    } else {
      setislodaing(false)
      if (res.data.message === 'Invalid token') {
        userLogout()
      }
    }
  }

  const stayavailableslots = async () => {
    if (startDate < endDate) {
      let slotsData = {
        sub_pkg_id: selectedId,
        start_date: startDate,
        end_date: endDate,
      }
      const res = await stayavailableslotsSerivce(slotsData)
      if (res?.data.status) {
        setstayavailableslots(res.data.data)
      } else {
        toast(res.data.message)
        if (res.data.message === 'Invalid token') {
          userLogout()
        }
      }
    } else if (startDate === endDate) {
      toast.error("Start date and End date can't be the same")
    }
  }

  const trekkingavailableslots = async (historydata) => {
    let slotsData = {
      sub_pkg_id: historydata.sub_pkg_id,
      date: historydata.date,
    }
    const res = await trekkingavailableslotsSerivce(slotsData)
    if (res?.data.status) {
      if (res.data.data.slots > 0) {
        navigate(`/paymentGateway/${historydata._id}`)
      }
    } else {
      toast(res.data.message)
      if (res.data.message === 'Invalid token') {
        userLogout()
      }
    }
  }
  const availableslotsHandler = (e, historydata) => {
    setSelectedId(historydata.sub_pkg_id)
    setSelectedBookingId(historydata._id)
    if (historydata.type === 'Stay') {
      popupcalander.current.toggle(e)
      setDates([])
      setstartDate(null)
      setEndDate(null)
    } else if (historydata.type === 'Trekking') {
      trekkingavailableslots(historydata)
    } else {
      navigate(`/paymentGateway/${historydata._id}`)
    }
  }

  useEffect(() => {
    getBookingHistoryHandler()
  }, [])

  useEffect(() => {
    if (isPaid) {
      window.location.reload(false)
    }
  }, [isPaid])

  useEffect(() => {
    if (dates.length === 2) {
      const [start, end] = dates
      setstartDate(convert(start))
      setEndDate(convert(end))
    }
  })

  useEffect(() => {
    if (stayavailableslotsdata.slots > 0) {
      navigate(`/paymentGateway/${selectedBookingId}`)
    }
  }, [stayavailableslotsdata])
  useEffect(() => {
    if (startDate && endDate) {
      stayavailableslots()
    }
  }, [startDate, endDate])

  if (!userInfo) {
    setloginPopup(true)
  }

  // Create a new Date object representing the current date and time
  var currentDate = new Date()

  // Get individual components of the date
  var day = currentDate.getDate()
  var month = currentDate.getMonth() + 1 // Months are zero-based, so we add 1
  var year = currentDate.getFullYear()

  // Format the date as a string in "DD-MM-YYYY" format
  var formattedDate = `${day < 10 ? '0' : ''}${day}-${
    month < 10 ? '0' : ''
  }${month}-${year}`

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className='myBookingsWrapper'>
          {/* <PrintScreen bookingSummaryinfo={bookingSummaryinfo} /> */}
          <div className='innerpage-banners'>
            <img src={innerBanner} alt='inner banner' />
            <h5 className='innerBanerTitle'>My Bookings</h5>
          </div>
          <div className='container'>
            {bookingHis.length === 0 ? (
              <div className='bookingConatinerSection'>
                <h1>No data found</h1>
              </div>
            ) : (
              bookingHis
                ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .map((historydata) => (
                  <div className='bookingBox'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='bookingImg'>
                          <img
                            src={`${BaseUrl}/${historydata.package_image}`}
                            alt='picc'
                          />
                          {/* <img src={myBookings1} alt="pic" /> */}
                        </div>
                      </div>
                      <div className='col-md-8'>
                        <div className='bookingTxt'>
                          <div className='row'>
                            <div className='col-md-8'>
                              <div className='bookingboxHead'>
                                <h3>{historydata.pkg_name}</h3>
                                <small>{historydata.type}</small>
                              </div>
                              <div className='bookingboxList'>
                                <ul>
                                  <li>
                                    Bill No:{' '}
                                    <span>{historydata.booking_id}</span>
                                  </li>
                                  {historydata.type === 'Stay' ? (
                                    <>
                                      <li>
                                        Check-in:{' '}
                                        <span>{historydata.start_date}</span>
                                      </li>
                                      <li>
                                        Check-out:{' '}
                                        <span>{historydata.end_date}</span>
                                      </li>
                                    </>
                                  ) : (
                                    <li>
                                      Date: <span>{historydata.date}</span>
                                    </li>
                                  )}
                                  <li>
                                    Time:{' '}
                                    <span>{historydata.reporting_time}</span>
                                  </li>
                                  <li>
                                    Total:{' '}
                                    <span>{historydata.total_amount}.00</span>
                                  </li>
                                </ul>
                                {historydata.booking_status === 'approved' ? (
                                  <div className=' mt-1'>
                                    <div className='row'>
                                      <p
                                        style={{
                                          fontSize: '15px',
                                          color: `${
                                            historydata.booking_status ===
                                            'approved'
                                              ? 'green'
                                              : 'red'
                                          }`,
                                        }}
                                      >
                                        {
                                          historydata.payment_json
                                            .transaction_error_desc
                                        }
                                      </p>
                                    </div>
                                    <div className='row'>
                                      <div className='col-md-6'>
                                        <p>
                                          Transaction ID :{' '}
                                          {
                                            historydata.payment_json
                                              .transactionid
                                          }{' '}
                                        </p>
                                      </div>
                                      <div className='col-md-6'>
                                        <p>
                                          Transaction Date :{' '}
                                          {historydata?.payment_json
                                            ?.transaction_date
                                            ? new Date(
                                                historydata.payment_json.transaction_date
                                              ).toLocaleDateString('en-GB')
                                            : ''}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className='row'>
                                    <p
                                      style={{
                                        fontSize: '15px',
                                        textTransform: 'capitalize',
                                        color: `${
                                          historydata.booking_status ===
                                          'approved'
                                            ? 'green'
                                            : 'red'
                                        }`,
                                      }}
                                    >
                                      {historydata.booking_status}
                                    </p>

                                    {/* {historydata.start_date > formattedDate ? ( */}
                                    {historydata.is_approval_required &&
                                    historydata.booking_status === 'pending' ? (
                                      <p
                                        style={{
                                          fontSize: '10px',
                                          textTransform: 'capitalize',
                                          color: `${
                                            historydata.booking_status ===
                                            'approved'
                                              ? 'green'
                                              : 'red'
                                          }`,
                                        }}
                                      >
                                        Note: You can proceed with the payment
                                        once the approval process is completed.
                                        After approval, the "Pay now" button
                                        will be enabled in my booking.
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>

                            {historydata.booking_status === 'approved' ? (
                              <div className='col-md-4 qrMainSession'>
                                <div className='qrConatiner'>
                                  <QRCode
                                    // size={160}
                                    style={{
                                      height: 'auto',
                                      maxWidth: '100%',
                                      width: '100%',
                                    }}
                                    value={
                                      historydata?._id.toString() || 'null'
                                    }
                                    viewBox={`0 0 356 356`}
                                  />
                                </div>

                                <div
                                  className='qrcode'
                                  style={{
                                    display:
                                      historydata?._id === bookingViewid
                                        ? 'none'
                                        : 'block',
                                  }}
                                >
                                  <button
                                    className='btn sm-btn'
                                    onClick={() =>
                                      getbookingsHandler(historydata?._id)
                                    }
                                    style={{ marginTop: '10px' }}
                                  >
                                    View Details
                                  </button>
                                  {/* <div className='btnSection'> */}
                                  <button
                                    className='btn sm-btn'
                                    onClick={() => {
                                      printHandler(historydata?._id)
                                    }}
                                    style={{
                                      marginTop: '10px',
                                      marginLeft: '5px',
                                    }}
                                  >
                                    Print
                                  </button>
                                  {/* </div> */}
                                </div>
                              </div>
                            ) : (
                              <div className='col-md-4 d-flex align-items-center justify-content-center'>
                                {historydata.is_approval_required ? null : historydata.booking_status ===
                                    'pending' &&
                                  formatDateToDDMMYYYY() <
                                    historydata.end_date ? (
                                  <button
                                    onClick={(e) =>
                                      availableslotsHandler(e, historydata)
                                    }
                                    className='mainButton'
                                  >
                                    Pay Now
                                  </button>
                                ) : null}

                                <div>
                                  <OverlayPanel ref={popupcalander}>
                                    <label htmlFor=''>
                                      Start Date - End Date
                                    </label>
                                    <Calendar
                                      value={dates}
                                      onChange={(e) => {
                                        setDates(e.value)
                                      }}
                                      selectionMode='range'
                                      readOnlyInput
                                      minDate={new Date()} // Set the minimum date to today
                                    />
                                  </OverlayPanel>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      {historydata?._id === bookingSummaryId ? (
                        <div className='tableConatinerbookinghis'>
                          {historydata.type !== 'Ticket' ? (
                            <table class='table table-bordered'>
                              <thead>
                                <tr>
                                  <th>Sl.No.</th>
                                  <th>Name</th>
                                  <th>Age</th>
                                  <th>Gender</th>
                                  <th>Mobile Number</th>
                                  <th>Nationality</th>
                                  <th>Proof Type</th>
                                  <th>Proof No.</th>
                                  <th>Insurance No.</th>
                                  {historydata.type !== 'Stay' && (
                                    <th>Group Lead</th>
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {bookingSummaryinfo?.details?.map(
                                  (userDetails, index) => (
                                    <tr key={userDetails._id}>
                                      <th>{index + 1}</th>
                                      <td>{userDetails.name}</td>
                                      <td>{userDetails.age}</td>
                                      <td>{userDetails.gender}</td>
                                      <td>{userDetails.mobile}</td>
                                      <td>{userDetails.user_category}</td>
                                      <td>{userDetails.id_proof_type}</td>
                                      <td>{userDetails.id_proof_number}</td>
                                      <td>{userDetails.insurance_number}</td>
                                      {historydata.type !== 'Stay' && (
                                        <td>
                                          {userDetails.is_group_leader
                                            ? 'Yes'
                                            : '-'}
                                        </td>
                                      )}
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          ) : (
                            ''
                          )}
                          <div className='btnSection'>
                            <button
                              className='mainButton printBtn'
                              onClick={handlePrint}
                            >
                              Print
                            </button>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                ))
            )}

            <div style={{ display: 'none' }}>
              <div ref={componentRef} style={{ width: '100%' }}>
                <PrintScreen bookingSummaryinfo={bookingSummaryinfo} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
