import React from "react";
import "./search.scss";
import banner from "../../../Assets/Images/inner-banner/inner-banner.jpg";
const Search = () => {
  return (
    <div className="SearchConatiner">
      <div className="SearchConatinerBanner">
        <img src={banner} alt="" />
        <h3>Search results</h3>
      </div>
      <div className="SearchConatinerContent">
        <div className="cardSection">
            <div className="imgConatiner"></div>
            <div className="contentSection"></div>
        </div>
      </div>
    </div>
  );
};

export default Search;
